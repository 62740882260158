import React, { useState, useEffect } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Link } from "react-router-dom";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PersonIcon from '@mui/icons-material/Person';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Navigate, useNavigate } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Alert from '@mui/material/Alert';
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  getAuth,
  sendEmailVerification
} from "firebase/auth";
import { auth } from "../../firebase.js";


const pages = ['過去の実行結果', 'レシピ一覧', '献立管理', "食材の費用管理"];
const pages_path = ['/school/jobs', '/school/recipes', '/school/menu-list', '/school/cost-list'];


function ResponsiveAppBar() {
  const [openMenu, setOpenMenu] = useState(null);
  const [openUserMenu, setOpenUserMenu] = useState(null);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    status: 'info',
    message: '',
  });
  const [user, setUser] = useState(null);

  useEffect(() => {
  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
    // console.log(currentUser);
  });
  }, []);
  
  // const user = auth.currentUser;
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setOpenMenu(null);
  };

  const handleOpenUserMenu = (event) => {
    setOpenUserMenu(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setOpenUserMenu(null);
  };
  const handleClose = () => {
    setSnackbarState({
        open: false,
        status: '',
        message: '',
    });
  };

  /* ↓関数「logout」を定義 */
  const logout = async () => {
    try{
      await signOut(auth);
      navigate("/school/")
      setSnackbarState({
        open: true,
        status: 'success',
        message: 'ログアウトしました',
      });
      
    }
    catch{
      setSnackbarState({
        open: true,
        status: 'error',
        message: 'エラーが起きました',
      });
    }
    
  }

  return (
    // <AppBar position="static" style={{ color: "#000000", backgroundColor: "#ffffff" }}>
    <AppBar position="sticky" style={{ color: "#000000", backgroundColor: "#ffffff" }}>
      <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarState['open']}
            autoHideDuration={6000}
            onClose={handleClose}
        >
        <Alert severity={snackbarState['status']}>{snackbarState['message']}</Alert>
      </Snackbar>
      {/* 大画面用 */}
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h5"
            // noWrap
            component={Link}
            to="/school"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            給食献立推薦
          </Typography>
          
          {/* 小画面用 */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            {/* ハンバーガーメニュー */}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuBookIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={openMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(openMenu)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page, i) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography 
                  textAlign="center"
                  component={Link}
                  to={pages_path[i]}
                  >
                    {page}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component={Link}
            to="/school"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            給食献立推薦
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page, i) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'black', display: 'block', fontSize: 15}}
                component={Link}
                to={pages_path[i]}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {user? (
              <>
              <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenUserMenu}
              color="inherit"
            >
              <AccountCircleIcon/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={openUserMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(openUserMenu)}
              onClose={handleCloseUserMenu}
              >
              <MenuItem key={"ユーザ設定"} onClick={handleCloseUserMenu}>
                <Typography 
                textAlign="center"
                component={Link}
                to={"/school/user-info"}
                >
                  {"ユーザ情報"}
                </Typography>
              </MenuItem>
              <MenuItem key={"ログアウト"} onClick={handleCloseUserMenu}>
                  <Typography 
                  textAlign="center"
                  component={Link}
                  onClick={logout}
                  >
                    {"ログアウト"}
                  </Typography>
                </MenuItem>
            </Menu>
          </>
            ):
            (<>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            {/* ハンバーガーメニュー */}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenUserMenu}
              color="inherit"
            >
              <AppRegistrationIcon />
            </IconButton>
            <Menu
              id="menu-register-appbar"
              anchorEl={openUserMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(openUserMenu)}
              onClose={handleCloseUserMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MenuItem key={"ログイン"} onClick={handleCloseUserMenu}>
                <Typography 
                textAlign="center"
                component={Link}
                to={"/school/login"}
                >
                  {"ログイン"}
                </Typography>
              </MenuItem>
              <MenuItem key={"ユーザ登録"} onClick={handleCloseUserMenu}>
                  <Typography 
                  textAlign="center"
                  component={Link}
                  to={"/school/register"}
                  >
                    {"ユーザ登録"}
                  </Typography>
                </MenuItem>
            </Menu>
          </Box>
              <>
              <Button href="/school/login" sx={{display: { xs: 'none', md: 'inline' }}}>
                ログイン
              </Button>
              <Button href="/school/register" sx={{display: { xs: 'none', md: 'inline' }}}>
                ユーザー登録
              </Button>
              </>
            </>
            )}  
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;