/* Login.js */

import React, { useState, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  getAuth,
  sendEmailVerification
} from "firebase/auth";
import { auth } from "../../firebase.js";
import { Navigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import SendIcon from '@mui/icons-material/Send';
import InputAdornment from '@mui/material/InputAdornment';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';
import Navigation from '../contents/Navigation';

const theme = createTheme();

const Login = () => {
    /* ↓state変数を定義 */
    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [verifyMailArart, setVerifyMailArart] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [verifyMail, setVerifyMail] = useState(true);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const [snackbarState, setSnackbarState] = useState({
        open: false,
        status: 'info',
        message: '',
    });

    const [user, setUser] = useState(null);

    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });
    }, []);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');

    useEffect(() => {
        if(email){
            setLoginEmail(email);
        }
    }, [email]);

    const handleClose = () => {
        setSnackbarState({
            open: false,
            status: 'info',
            message: '',
        });
    };


    const signin = async (e) => {
        e.preventDefault();
        setVerifyMail(false);
        try {
            const userCredencial = await signInWithEmailAndPassword(
                auth,
                loginEmail,
                loginPassword
            );
            console.log("login")
            const isNotVerified = !userCredencial.user.emailVerified
            setVerifyMail(userCredencial.user.emailVerified)
            if (isNotVerified) {
                // await reSendVerifyMail(userCredencial.user)
                // console.log(auth.currentUser);
                // console.log("認証してない");
                setVerifyMailArart(true)
                await setSnackbarState({
                    open: true,
                    status: 'error',
                    message: 'メールアドレスの認証をしてください',
                });
                await signOut(auth);
                // console.log(auth.currentUser);
            }
            // return
        } catch (error) {
            console.log(error.code)
            switch (error.code) {
            case "auth/user-not-found":
                setSnackbarState({
                    open: true,
                    status: 'error',
                    message: 'ユーザが見つかりません',
                });
                break
            case "auth/invalid-login-credentials":
                setSnackbarState({
                    open: true,
                    status: 'error',
                    message: 'メールアドレスまたはパスワードが違います\n(新規登録がお済みでない方は登録をお願いします)',
                });
                break
            case "auth/invalid-email":
                setSnackbarState({
                    open: true,
                    status: 'error',
                    message: '無効なメールアドレスです',
                });
                break
            case "auth/missing-password":
                setSnackbarState({
                    open: true,
                    status: 'error',
                    message: 'パスワードを入力してください',
                });
                break
            case "auth/wrong-password":
                setSnackbarState({
                    open: true,
                    status: 'error',
                    message: 'メールアドレスまたはパスワードが違います',
                });
                break
            default:
                setSnackbarState({
                    open: true,
                    status: 'error',
                    message: 'エラーが起きました' + error.code,
                });
                break
                // その他のエラー時の処理
            }
            // return
        }
        };

        const reSendVerifyMail = async (e) => {
            const actionCodeSettings = {
                // パスワード再設定後のリダイレクト URL
                url: 'https://menu-recommend.com/school/login?email=' + loginEmail
            }
            e.preventDefault();
            try {
                const userCredencial = await signInWithEmailAndPassword(
                    auth,
                    loginEmail,
                    loginPassword
                );
                await sendEmailVerification(userCredencial.user, actionCodeSettings)
                .then(() => {
                    setSnackbarState({
                        open: true,
                        status: 'success',
                        message: '認証メールを送信しました',
                    });
                });
                await signOut(auth);
                
            } catch (error) {
                switch (error.code) {
                case "auth/too-many-requests":
                    setSnackbarState({
                        open: true,
                        status: 'error',
                        message: 'しばらく時間を置いてから再送信してください' + error.code,
                    });
                    // 1分以内は再送できずこのエラーになる.その時の処理.
                    break
                case "auth/user-not-found":
                    setSnackbarState({
                        open: true,
                        status: 'error',
                        message: 'ユーザが見つかりません',
                    });
                    break
                case "auth/invalid-login-credentials":
                    setSnackbarState({
                        open: true,
                        status: 'error',
                        message: 'メールアドレスまたはパスワードが違います',
                    });
                    break
                case "auth/invalid-email":
                    setSnackbarState({
                        open: true,
                        status: 'error',
                        message: '無効なメールアドレスです',
                    });
                    break
                case "auth/missing-password":
                    setSnackbarState({
                        open: true,
                        status: 'error',
                        message: 'パスワードを入力してください',
                    });
                    break
                case "auth/wrong-password":
                    setSnackbarState({
                        open: true,
                        status: 'error',
                        message: 'メールアドレスまたはパスワードが違います',
                    });
                    break
                default:
                    setSnackbarState({
                        open: true,
                        status: 'error',
                        message: 'エラーが起きました' + error.code,
                    });
                    break
                    // その他のメール送信失敗時の処理
                }
            }
        }
        const Item = styled(Paper)(({ theme }) => ({
            ...theme.typography.body2,
            textAlign: 'center',
            color: theme.palette.text.secondary,
            height: 30,
            width: 130,
            lineHeight: '30px',
            backgroundColor: "#F5DCDE",
        }));
    

    return (
        <>
        {/* ↓ログインしている場合、マイページにリダイレクトする設定 */}
        {user && verifyMail? (
            // <>あ</>
            <Navigate to={`/school`} />
        ) : (
        <>
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarState['open']}
            autoHideDuration={6000}
            onClose={handleClose}
        >
        <Alert severity={snackbarState['status']}>{snackbarState['message']}</Alert>
        </Snackbar>
        <ThemeProvider theme={theme}>
        <Navigation/>
        <Grid sx={{ flexGrow: 1, padding: 5 }} container justifyContent="center" alignItems="center" spacing={2} rowSpacing={2}> 
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{p: 3}}>
                <Grid item>
                {/* <Grid item> */}
                    <Typography variant="h4">
                        ログイン
                    </Typography>
                </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{p: 1.5}}>
                    <Grid item>
                        <Item>メールアドレス</Item>
                    </Grid>
                    <Grid item> 
                        <OutlinedInput
                            name="email"
                            type="email"
                            value={loginEmail}
                            onChange={(e) => setLoginEmail(e.target.value)}
                            // label="Select"
                            // defaultValue={nutritions[2]['value']}
                            size="small"
                            // helperText="Please select your currency"
                            sx={{ width: '30ch' }}
                            required
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{p: 1.5}}>
                    <Grid item>
                        <Item>パスワード</Item>
                    </Grid>
                    <Grid item>
                    <FormControl variant="outlined">
                        {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            value={loginPassword}
                            onChange={(e) => setLoginPassword(e.target.value)}
                            size="small"
                            sx={{ width: '30ch' }}
                            required
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                //   onMouseDown={handleMouseDownPassword}
                                edge="end"
                                >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            }
                            // label="Password"
                        />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>    
            <Grid item xs={12}>
                <Grid container sx={{pt: 1.5}} justifyContent="center" alignItems="center">
                    <Grid item>
                        <Button variant="outlined" endIcon={<SendIcon />}  onClick={signin}>送信</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item >
                            <Link href="/school/change-password" variant="body2">パスワードをお忘れの方はこちら</Link>
                        </Grid>
                        <Grid item >
                            <Link href="/school/register" variant="body2">新規登録はこちら</Link>
                        </Grid>
                </Grid>
            </Grid>
            {verifyMailArart?
            <>
            <Grid item xs={12}>
                <Grid container sx={{pt: 5}} justifyContent="center" alignItems="center">
                    <Button variant="outlined" endIcon={<SendIcon />} onClick={reSendVerifyMail}>メールアドレス認証のメールを再度送信</Button>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                    <Typography variant="body2" sx={{color: "red"}}>
                    メールアドレスが認証されていません
                </Typography>
                </Grid>
            </Grid>
            </>
            :<></>}
        </Grid>
        </ThemeProvider>
        </>
        )}
        </>
    );
};

export default Login;