import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { spacing } from '@mui/system';
import SendIcon from '@mui/icons-material/Send';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
// import { DatePicker } from '@mui/x-date-picker';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-picker/DatePicker';
// import { DatePicker } from '@mui/x-date-picker-pro';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/ja';
import ja from 'date-fns/locale/ja'
import jaLocale from 'date-fns/locale/ja'
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
// import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import homeImage from "./home.png";
import schoolImage from "./school.png";
import Link from '@mui/material/Link';


export const Portfolio = () => {
    return (
    <>
    {/* <Grid xs={0} md={12} sx={{ flexGrow: 1, p display: { xs: 'none', md: 'block' }}} container justifyContent="center" alignItems="center" spacing={2} rowSpacing={2}></Grid> */}
    <Grid sx={{ flexGrow: 1, padding: 5}} container justifyContent="center" alignItems="center" spacing={2} rowSpacing={2}> 
        <Grid item xs={12} md={6}  sx={{padding: 0.5 }}>
            <Grid container justifyContent="center" alignItems="center" spacing={1}>
                <Grid item>
                    <Card sx={{ maxWidth: 600 }}>
                    <CardMedia
                        sx={{ height:280}}
                        image={homeImage}
                        title="home"
                    />
                    <CardContent>
                        <Link
                        // component="button"
                        variant="h7"
                        href="https://www.ipa.go.jp/jinzai/mitou/target/2022/gaiyou_tr-1.html"
                        // onClick={() => {
                        //     console.info("I'm a button.");
                        // }}
                        >
                        2022年度未踏ターゲット事業（秋島・藤元PJ）
                        </Link>
                        <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb: 1}}>
                        アニーリングマシンを用いた<br/>レシピ推薦サービスの開発
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        家庭向けの献立推薦サービスです。
                        </Typography>
                        
                    </CardContent>
                    <CardActions  sx={{textAlign: "center", justifyContent: "center" }}>
                        <Button href="https://mitou-tg-recipe.github.io/menu-recommend/" variant='outlined' sx={{p: 2, mt: 1, mb: 3}}>
                            アプリケーションを試してみる
                        </Button>
                    </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} md={6} sx={{padding: 0.5 }}>
            <Grid container justifyContent="center" alignItems="center" spacing={1}>
                <Grid item>
                    <Card sx={{ maxWidth: 600 }}>
                    <CardMedia
                        sx={{ height: 280 }}
                        image={schoolImage}
                        title="school"
                    />
                    <CardContent>
                        <Link
                        // component="button"
                        variant="h7"
                        href="https://www.ipa.go.jp/jinzai/mitou/target/2023/gaiyou_tr-1.html"
                        // onClick={() => {
                        //     console.info("I'm a button.");
                        // }}
                        >
                        2023年度未踏ターゲット事業（井上・秋島PJ）
                        </Link>
                        <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb: 1}}>
                        アニーリングマシンを用いた<br/>学校給食推薦システムの開発
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        学校給食向けの献立推薦サービスです。
                        </Typography>
                    </CardContent>
                    <CardActions sx={{textAlign: "center", justifyContent: "center" }}>
                        <Button href="./school" variant='outlined' sx={{p: 2, mt: 1, mb: 3}}>
                            アプリケーションを試してみる
                        </Button>
                    </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
    </>
);
}

