import React from "react";
import { Link } from "react-router-dom";
import Navigation from '../contents/Navigation'
import Grid from '@mui/material/Grid';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import {default as styled_emotion} from '@emotion/styled'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { tableCellClasses } from '@mui/material/TableCell';
import Chip from '@mui/material/Chip';
import { animateScroll as scroll } from "react-scroll";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {useLocation, useNavigate} from "react-router-dom";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  getAuth,
  sendEmailVerification
} from "firebase/auth";
import { auth } from "../../firebase.js";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

//
// データ整形
//

// import Recipes from '../recipes_0725_edit_costari_seasonari.json';
import Menu from '../menu_0814.json';
import { click } from "@testing-library/user-event/dist/click";
import Footer from "../contents/Footer";

// const constraints_name = [
//   "staple": "主菜"
// ]

// const api_url = "http://localhost:8000/"
const api_url = "https://api.menu-recommend.com/"

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}



CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ToggleContent = ({ children, title }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleContent = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div onClick={toggleContent} style={{ cursor: 'pointer' }}>
        {isOpen ? '▼' : '▶'} {title}
      </div>
      {isOpen && <div>{children}</div>}
    </div>
  );
};


export const StyleWrapper = styled_emotion.div`
.fc-day-other {
    background-color: lightgray;
}

.fc-day-sat {
    background-color: #eaf4ff;
}
.fc-day-sun {
    background-color: #ffeaea;
}
.fc-daygrid-event {
    &.event_holiday {
        background-color: #ffeaea;
    //   display: none;
    }
  }
// .fc {
//     width: 80%;
//     // display: flex;
//     // align-items: center;
//     // justify-content: center;
//     // text-align: center;
// }  
`;

const category_name = ["主菜", "副菜", "主食", "汁物", "デザート"]
const category_name_en = ["main", "side", "staple", "soup", "dessert"]
const category_color = ["#ffeddb", "#edffdb", "#ffdbed", "#dbedff", "#ffffdb"]

const genre_name = ["和風", "洋風", "中華風", "韓国風", "エスニック"]
const genre_color = ["#C66600", "#3366ff", "#ff7f7f", "#387d39", "#6f5436"]

const theme = createTheme();

function ResultTable({navigate, location, Menu, year, month, start_day, tabIndex, input}) {
  const menu_events = [];
  // // console.log(year)

  const toCalendar = () => {
    navigate('/school/result-calendar' , {state: {'body':location.state.body, 'year':location.state.year, 'month':location.state.month, 'tabIndex': tabIndex, 'input': location.state.input}});
  }

  let nutritions = {}
  nutritions["energy"] = 0;
  nutritions["protein"] = 0;
  nutritions["lipid"] = 0;
  nutritions["sodium"] = 0;

  let sum_cost = 0
  let constraints = []

  const menuWithDetail = [];

  // // console.log(Menu)
  Menu.forEach(function(week_result, i) {
      // // console.log(week_result['menus']);
      // // console.log(week_result['H_energy'])
      constraints.push(week_result['H_energy'])
      week_result['menus'].forEach(function(day_result, j) {
          let data = {};
          data["date"] = year+'-'+('0'+month).slice(-2)+'-'+('0'+(start_day+i*7+j)).slice(-2);
          // // console.log(data["date"]);
          data["overview"] = {}
          // 牛乳分はAPIで足されている
          data["overview"]["energy"] = day_result['info']['energy'];
          data["overview"]["protein"] = day_result['info']['protein'];
          data["overview"]["lipid"] = day_result['info']['fat'];
          data["overview"]["sodium"] = day_result['info']['sodium'];
          data["overview"]["cost"] = day_result['info']['cost'];
          // data["eval"] = {
          //   "like": false,
          //   "dislike": false
          // };
          
          // 主食 主菜 副菜 汁物 デザート の順にしたい
          // indexは、2, 0, 1, 3, 4 
          let category_array = [];
          for(let i=0;i<5;i++){
              category_array.push([]);
          }
          day_result['menu'].forEach(function(recipe, k) {
              category_array[recipe['category']].push(recipe);
          });
          data["menu"] = [
              ...category_array[2],
              ...category_array[0],
              ...category_array[1],
              ...category_array[3],
              ...category_array[4]
          ]
          nutritions["energy"] += data["overview"]["energy"];
          nutritions["protein"] += data["overview"]["protein"];
          nutritions["lipid"] += data["overview"]["lipid"];
          nutritions["sodium"] += data["overview"]["sodium"];
          sum_cost += data["overview"]["cost"];
          menuWithDetail.push(data);
      });
  });
  // // console.log(menuWithDetail);

  menuWithDetail.forEach((row, i) => {
    for(let j=0;j<row["menu"].length;j++){
      menu_events.push(
        {
            title: row["menu"][j]["title"],
            start: row["date"], 
            borderColor: "gray", 
            backgroundColor: category_color[row["menu"][j]["category"]],
            textColor: "black"
        }
      );
    }

    //最後に牛乳
    menu_events.push(
        {
            title:'牛乳', 
            start: row["date"],   
            borderColor: "gray", 
            backgroundColor: "#ffffff", 
            textColor: "black"
        }
    );
  });

  const ConstraintsComponent = ({ constraints }) => {
    // console.log(constraints);

    let strong_penalty = ["staple", "main", "side", "soup", "dessert", "energy", "protein", "fat", "sodium", "cost"]
    let le1 = ["energy", "protein", "fat", "sodium", "cost"] //1以下ならよし
    let weak_penalty = ["duplication", "genre", "combination", "variety", "evaluation"]

    let constraint_name = {
        "staple": "主食", "main": "主菜", "side": "副菜", "soup": "汁物", "dessert": "デザート", 
        "energy": "エネルギー", "protein": "たんぱく質", "fat": "脂質", "sodium": "ナトリウム", "cost": "費用"
    }

    // const [feasibleFlag, setFeasibleFlag] = useState(true);
    let feasible_flag = true;

    return (
        <Grid container spacing={1} sx={{p: 3}}>
            <Grid container justifyContent="center" alignItems="center">
            <Typography align="center">満たしていない制約</Typography>
            </Grid>
            {constraints.map((constraint, index) => {
                feasible_flag = true;
                // valueが0でない場合のみChipを表示
                return (
                <Grid item xs={12} key={index} sx={{ m: 0.5 }}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Item>{index+1}週目</Item>
                        <Box sx={{ display: 'flex', ml: 3 }}>
                            {Object.keys(constraint).map(key => {
                                // 条件に合致する場合にのみChipを表示
                                if (strong_penalty.includes(key)){
                                    if(le1.includes(key)){
                                        if(constraint[key] > 1.5){
                                            // console.log(key, constraint[key])
                                            feasible_flag = false
                                            return (
                                                <Chip
                                                    key={key}
                                                    label={constraint_name[key]}
                                                    color="default"
                                                    sx={{
                                                        fontColor: "#ffffff",
                                                        marginRight: 1
                                                        // 他のスタイルを設定することもできます
                                                    }}
                                                />
                                            );
                                        }
                                    }
                                    else{
                                        if(constraint[key] !== 0){
                                            // console.log(key, constraint[key])
                                            feasible_flag = false
                                            return (
                                                <Chip
                                                    key={key}
                                                    label={constraint_name[key]}
                                                    color="default"
                                                    sx={{
                                                        fontColor: "#ffffff",
                                                        marginRight: 1
                                                        // 他のスタイルを設定することもできます
                                                    }}
                                                />
                                            );
                                        }
                                    }   
                                }
                            })}
                            {feasible_flag && (
                                <Typography>全て満たしています！</Typography>
                            )}
                        </Box>
                    </Grid>
                </Grid>
    )})}
          </Grid>
      );
      
  };

  
  const Item = styled(Paper)(({ theme }) => ({
      ...theme.typography.body2,
      textAlign: 'center',
      color: theme.palette.text.secondary,
      height: 30,
      width: 100,
      lineHeight: '30px',
      backgroundColor: "#F5DCDE",
    }));

  const ItemNutrition = styled(Paper)(({ theme }) => ({
      ...theme.typography.body2,
      textAlign: 'center',
      color: theme.palette.text.secondary,
      height: 30,
      width: 100,
      lineHeight: '30px',
      backgroundColor: "#ffd4b1",
    }));



  // 栄養素用
  const [nutritionCategory, setNutritionCategory] = useState('0');
  const [user, setUser] = useState(null);
  React.useEffect(()=>{
    if(String(window.location.href).includes('#') ){
      window.location.href = window.location.href //無理矢理ページ内遷移
    }
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      // console.log(currentUser)
  });

  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
    <ThemeProvider theme={theme}>
        <Grid sx={{ flexGrow: 1, padding: 1 }} container rowSpacing={1}> 
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
              {/* <Button href="./result-calendar" variant="outlined">カレンダー表示</Button> */}
              <Button onClick={toCalendar} variant="outlined">カレンダー表示</Button>
            </Grid>
          </Grid>
          {/* {Object.keys(constraints).some(key => !constraints[key]) && (
                <Grid item xs={12} sx={{ m: 3 }}>
                    <Grid container justifyContent="center" alignItems="center">
                    <Typography align="center">満たしていない制約</Typography>
                    <Box sx={{ display: 'flex', ml: 3 }}>
                        {Object.keys(constraints).filter(key => !constraints[key]).map(key => (
                        <Chip
                            key={key}
                            label={key}
                            color="default"
                            sx={{
                            fontColor: "#ffffff",
                            marginRight: 1
                            // 他のスタイルを設定することもできます
                            }}
                        />
                        ))}
                    </Box>
                    </Grid>
                </Grid>
                )} */}
        </Grid>
    </ThemeProvider>

    <CollapsibleTable initMenuWithDetail={menuWithDetail} nutritions={nutritions} sum_cost={sum_cost} tabIndex={tabIndex} user={user} input={input}/>
    <ConstraintsComponent constraints={constraints}/>
    <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{pt:3, pb: 6, pl: 5, pr: 2}}>
            <ToggleContent title="制約とは？">
                <Grid container direction="column" alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{p: 2}}>
                        このアプリケーションでは、献立をさまざまな条件に基づいて作成しています。<br />
                        特に、以下の4つの条件が満たされているかを確認して、上の「満たされていない制約」に表示しています。
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper sx={{p: 3}}>
                        <Typography variant="body1">
                            ① 献立の構成 (主食と主菜が1品、副菜、汁物、デザートが0-1品)<br />
                            ② 献立の栄養素の合計値を理想値に近づける<br />
                            ③ 費用を理想値に近づける<br />
                            ④ 同じレシピが月に2回以上出ないようにする<br />
                        </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{p: 2}}>
                        この条件以外に、以下の条件をなるべく満たすことができるように最適化をしています。
                        </Typography>
                        <Paper sx={{p: 3}}>
                        <Typography variant="body1">
                        ⑤ 1日分の献立の中での料理のジャンルをそろえる<br />
                        ⑥ 主食に合うレシピを推薦する<br />
                        ⑦ 似ている献立(使っている食材が同じ、料理のジャンルが同じ)が隣りあわないようにする<br />
                        ⑧ ユーザからの評価(悪いと判断されたレシピの組み合わせは出さないようにする)
                        </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{p: 2}}>
                        現在表示されている献立では満たされていなくても、何回か推薦を試すことで満たされることもあります。
                        </Typography>
                    </Grid>
                </Grid>
            </ToggleContent>
            </Grid>
            

    </>
);

}

export const ResultTables = () => {
  const [tabValue, setTabValue] = useState(0);

  const navigate = useNavigate();
  const location = useLocation(); 
  let Menus = location.state?.body || [];
  let month = location.state?.month || 0;
  let year = location.state?.year || 0;
  let input_data = location.state?.input || {
    nutritions: {
        energy: null,
        fat: null,
        protein: null,
        sodium: null
    },
    cost_day: null
  };

  React.useEffect(() => {
    if(location.state.tabIndex){
        setTabValue(location.state.tabIndex);
    }
  },[])

  const getMonday = (requestMonth) => {
    // console.log(requestMonth)
    for(let i=1;i<10;i++){
        const target = new Date(year,requestMonth-1,i);
        // console.log(target)
        // console.log(target.getDay())
        if(target.getDay() === 1){
            return i
        }
    }
  }

  // console.log(month);
  const start_day = getMonday(month);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  // Menus = [Menus];
  // console.log(Menus)

  return (
    <>
    <Navigation/>  

    <Box sx={{ width: '100%', pt:3 }}>
    <ResultTable navigate={navigate} location={location} Menu={Menus} year={year} month={month} start_day={start_day} tabIndex={0} input={input_data}/>
      {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
            {Menus.map((Menu, index) => {return <Tab label={index} {...a11yProps(index)}/>})}
        </Tabs>
      </Box>
            {Menus.map((Menu ,index) => 
                {return (
                  <CustomTabPanel value={tabValue} index={index}>
                  <ResultTable navigate={navigate} location={location} Menu={Menu} year={year} month={month} start_day={start_day} tabIndex={index}/>
                  </CustomTabPanel>
            );})} */}

    </Box>
    <Footer/>
    {/* <ResultTable navigate={navigate} location={location} Menu={Menu} month={month} start_day={start_day} /> */}
    </>
  );
  
}

function Row(props) {
  const { recipe } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">
          <>
          <Box sx={{ 
            display: 'flex'
          }}>
          <Chip 
          label={genre_name[recipe.genre]} 
          color="primary"
          sx={{
              backgroundColor: genre_color[recipe.genre],
              fontColor: "#ffffff",
              marginRight: 1
          }} 
          />
          <Chip 
          label={category_name[recipe.category]} 
          sx={{
              backgroundColor: category_color[recipe.category],
              edgecolor: "#000000"
          }} 
          />
          </Box>
          </>
          
          </TableCell>
        <TableCell component="th" scope="row" align="center">
          {recipe.title}
        </TableCell>
        <TableCell align="right">{recipe.nutritions.エネルギー.toFixed(1)}</TableCell>
        <TableCell align="right">{recipe.nutritions.たんぱく質.toFixed(1)}</TableCell>
        <TableCell align="right">{recipe.nutritions.脂質.toFixed(1)}</TableCell>
        <TableCell align="right">{recipe.nutritions.ナトリウム.toFixed(1)}</TableCell>
        <TableCell align="right">{recipe.cost.toFixed(1)}</TableCell>
         {/* TO DO */}
        {/* <TableCell align="right">{recipe.overview.energy}</TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                詳細
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">食材名</TableCell>
                    <TableCell align="right">分量&nbsp;(g)</TableCell>
                    <TableCell align="right">エネルギー&nbsp;(kcal)</TableCell>
                    <TableCell align="right">たんぱく質&nbsp;(g)</TableCell>
                    <TableCell align="right">脂質&nbsp;(g)</TableCell>
                    <TableCell align="right">ナトリウム&nbsp;(mg)</TableCell>
                    <TableCell align="right">費用&nbsp;(円)</TableCell>
                    {/* <TableCell align="right">コマンド</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recipe.ingredients.map((ingredient) => (
                    <TableRow key={ingredient.name}>
                      <TableCell component="th" scope="row" align="left">{ingredient.name}</TableCell>
                      <TableCell align="right">{ingredient.amount}</TableCell>
                      <TableCell align="right">{ingredient.energy}</TableCell>
                      <TableCell align="right">{ingredient.protein}</TableCell>
                      <TableCell align="right">{ingredient.lipid}</TableCell>
                      <TableCell align="right">{ingredient.sodium}</TableCell>
                      <TableCell align="right">0</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> 
    </React.Fragment>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "rgba(0, 0, 0, 0.56)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const AcceptDialog = ({ open, handleClose, recipe, data, user}) => {
  const dateObject = new Date(data.date);

  const [selectedYear, setSelectedYear] = useState(dateObject.getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(dateObject.getMonth() + 1);
  const [selectedDay, setSelectedDay] = useState(dateObject.getDate());
  const [isInvalid, setIsInvalid] = useState(false);


  useEffect(() => {
    const dateObject = new Date(data.date);
    setSelectedYear(dateObject.getFullYear());
    setSelectedMonth(dateObject.getMonth() + 1);
    setSelectedDay(dateObject.getDate());
  }, [data.date]);

  // const [selectedYear, setSelectedYear] = useState(2024);
  // const [selectedMonth, setSelectedMonth] = useState(1);
  // const [selectedDay, setSelectedDay] = useState(3);
  // console.log(dateObject.getFullYear(), dateObject.getMonth() + 1, dateObject.getDate())
  // console.log(selectedYear, selectedMonth, selectedDay)
  

  const isValidDate = (year, month, day) => {
    if(year == '' || month == '' || day == ''){
      return false
    }
    // 月は0から始まるため、入力された月の値から1を引く
    const date = new Date(year, month - 1, day);
    
    // Dateオブジェクトが有効な日付かどうかを確認
    // console.log(date)
    return !isNaN(date.getTime());
  };

  const handleMenuAccept = async (data, year, month, day) => {
    try {
      if (isValidDate(year, month, day)) {
        // console.log('有効な日付です');
        setIsInvalid(false);
      } else {
        // console.log('無効な日付です');
        setIsInvalid(true);
        return;
      }
      // Promiseオブジェクトを保持するための配列
      const promises = [];

      data.menu.forEach((recipe) => {
        // 各レシピに対してfetchを実行し、Promiseオブジェクトを配列に追加
        promises.push(
          fetch(`${api_url}school/menus`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': `Bearer ${user.accessToken}`,
            },
            body: JSON.stringify({
              "date": `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`,
              "recipe_id": recipe.id
            }),
          })
        );
      });

      // Promiseオブジェクトを並行して実行
      const responses = await Promise.all(promises);

      // すべてのリクエストが成功したかどうかを確認
      const allResponsesSuccessful = responses.every(response => response.ok);

      if (allResponsesSuccessful) {
        alert('登録しました！');
        // ダイアログを閉じる
        handleClose();
      } else {
        throw new Error('Some POST requests failed');
      }
    } catch (error) {
      console.error('Error:', error.message);
      // エラーが発生した場合の処理をここに追加できます
    }
  };

    return (
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle>
          <TextField
            size="small"
            sx={{ width: '8ch'}}
            InputProps={{
              inputMode: 'numeric', pattern: '[0-9]*',
              // endAdornment: <InputAdornment position="end">g未満</InputAdornment>,
              style: { height: "30px"},
            }}
            value={selectedYear}
            onChange={(event)=>setSelectedYear(event.target.value)}
          />
          年
          <TextField
            size="small"
            sx={{ width: '6ch'}}
            InputProps={{
              inputMode: 'numeric', pattern: '[0-9]*',
              // endAdornment: <InputAdornment position="end">g未満</InputAdornment>,
              style: { height: "30px"},
            }}
            value={selectedMonth}
            onChange={(event)=>setSelectedMonth(event.target.value)}
          />
          月
          <TextField
            size="small"
            sx={{ width: '6ch'}}
            InputProps={{
              inputMode: 'numeric', pattern: '[0-9]*',
              // endAdornment: <InputAdornment position="end">g未満</InputAdornment>,
              style: { height: "30px"},
            }}
            value={selectedDay}
            onChange={(event)=>setSelectedDay(event.target.value)}
          />
          日 の献立として採用する</DialogTitle>
          <DialogContent>
          {isInvalid ? (
          // 条件が true の場合に表示されるコンポーネント
          <Grid container direction="column" alignItems="center" spacing={1}>
          <Typography>不正な日付が入力されています</Typography>
          </Grid>
        ) : (
          // 条件が false の場合に表示されるコンポーネント（何も表示しない、または別のコンポーネントを表示するなど）
          null
        )}
        <Grid container alignItems="center" justifyContent="center" spacing={1}>
        <Button onClick={() => handleMenuAccept(data, selectedYear,selectedMonth,selectedDay)}>はい</Button>
        <Button onClick={handleClose}>キャンセル</Button>
        </Grid>
        </DialogContent>
         
        {/* <RecipeDetailsCard recipe={recipe} /> */}
        {/* </Dialog></DialogContent> */}
      </Dialog>
    );
  };


  const GoodDialog = ({ open, handleClose, recipe, data, user}) => {
    // console.log(data);
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
      setSelectedItems([]);
    }, [data]);

    const handleCheckboxChange = (itemId) => {
      // 選択されたアイテムの ID を更新する
      if (selectedItems.includes(itemId)) {
        setSelectedItems(selectedItems.filter((id) => id !== itemId));
      } else {
        setSelectedItems([...selectedItems, itemId]);
      }
    };

    const handleMenuAccept = async () => {
      if(user){
        // console.log('選択されたアイテム:', selectedItems);

        let request_body = {evals: selectedItems}

        try {
          const response = await fetch(`${api_url}school/good_evals`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': `Bearer ${user.accessToken}`
            },
            body: JSON.stringify(request_body),
          });
    
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          alert('登録しました！');
          // console.log('POST request successful');
        } catch (error) {
          console.error('Error:', error.message);
        }
      }
      
      else{
        alert("ユーザ登録をすると評価が反映されます！")
      }
    };
  
      return (
        <Dialog open={open} onClose={handleClose} maxWidth="md">
          <DialogTitle>好ましいレシピの組み合わせを選択してください</DialogTitle>
          <DialogContent sx={{ textAlign: 'center' }}>
          {data.menu && data.menu.map((menuItem) => (
            <FormControlLabel
              key={menuItem.id}
              control={
                <Checkbox
                  checked={selectedItems.includes(menuItem.id)}
                  onChange={() => handleCheckboxChange(menuItem.id)}
                />
              }
              label={menuItem.title}
            />
          ))}
          <Grid container alignItems="center" justifyContent="center" spacing={1} sx={{pt: 3}}>
          <Button onClick={handleMenuAccept}>登録する</Button>
          <Button onClick={handleClose}>キャンセル</Button>
          </Grid>
          </DialogContent>
        </Dialog>
      );
    };

  const BadDialog = ({ open, handleClose, recipe, data, user}) => {
      // console.log(data);
      const [selectedItems, setSelectedItems] = useState([]);

      useEffect(() => {
        setSelectedItems([]);
      }, [data]);

      const handleCheckboxChange = (itemId) => {
        // 選択されたアイテムの ID を更新する
        if (selectedItems.includes(itemId)) {
          setSelectedItems(selectedItems.filter((id) => id !== itemId));
        } else {
          setSelectedItems([...selectedItems, itemId]);
        }
      };

      const handleMenuAccept = async () => {
        // console.log('選択されたアイテム:', selectedItems);
        if(user){
          let request_body = {evals: selectedItems}

          try {
            const response = await fetch(`${api_url}school/bad_evals`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${user.accessToken}`
              },
              body: JSON.stringify(request_body),
            });
      
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            alert('登録しました！');
            // console.log('POST request successful');
          } catch (error) {
            console.error('Error:', error.message);
          }
        }
        else{
          alert("ユーザ登録をすると評価が反映されます！")
        }

        
      };
  
      return (
        <Dialog open={open} onClose={handleClose} maxWidth="md">
          <DialogTitle>好ましくないレシピの組み合わせを選択してください</DialogTitle>
          <DialogContent sx={{ textAlign: 'center' }}>
          {data.menu && data.menu.map((menuItem) => (
            <FormControlLabel
              key={menuItem.id}
              control={
                <Checkbox
                  checked={selectedItems.includes(menuItem.id)}
                  onChange={() => handleCheckboxChange(menuItem.id)}
                />
              }
              label={menuItem.title}
            />
          ))}
          <Grid container alignItems="center" justifyContent="center" spacing={1} sx={{pt: 3}}>
          <Button onClick={handleMenuAccept}>登録する</Button>
          <Button onClick={handleClose}>キャンセル</Button>
          </Grid>
          </DialogContent>
        </Dialog>
      );
    };


export default function CollapsibleTable({initMenuWithDetail, nutritions, sum_cost, tabIndex, user, input}) {
  const [liked, setLiked] = useState(false);
  const [disLiked, setDisLiked] = useState(false);
  const [menuWithDetail, setMenuWithDetail] = useState(initMenuWithDetail);

  // console.log(menuWithDetail);

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 30,
    width: 100,
    lineHeight: '30px',
    backgroundColor: "#F5DCDE",
  }));

  const ItemNutrition = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 30,
    width: 100,
    lineHeight: '30px',
    backgroundColor: "#ffd4b1",
  }));

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const [selectedRecipe, setSelectedRecipe] = useState([]);
  const [isDetailsDialogOpen, setDetailsDialogOpen] = useState(false);

  const [isGoodDialogOpen, setGoodDialogOpen] = useState(false);
  const [isBadDialogOpen, setBadDialogOpen] = useState(false);

  const handleRowClick = (params) => {
    // レシピの詳細情報をセットしてダイアログを開く
    if(user){
      setSelectedRecipe(params);
      // console.log(params);
      setDetailsDialogOpen(true);
    }
    else{
      alert("ユーザ登録をすると、献立の保存・管理ができます！")
    }
  };

  const handleGoodClick = (params) => {
    // レシピの詳細情報をセットしてダイアログを開く
      setSelectedRecipe(params);
      // console.log(params);
      setGoodDialogOpen(true);
  };

  const handleBadClick = (params) => {
    setSelectedRecipe(params);
    // console.log(params);
    setBadDialogOpen(true);
  };

  const handleCloseDetailsDialog = () => {
    // ダイアログを閉じる
    setDetailsDialogOpen(false);
  };

  const handleCloseGoodDialog = () => {
    // ダイアログを閉じる
    setGoodDialogOpen(false);
  };

  const handleCloseBadDialog = () => {
    // ダイアログを閉じる
    setBadDialogOpen(false);
  };

  // const handleLikeClick = (dayMenu) => {
  //   const updatedMenu = [...menuWithDetail];
  //   const dayIndex = updatedMenu.findIndex((day) => day === dayMenu);
  
  //   updatedMenu[dayIndex].eval.like = !dayMenu.eval.like;
  //   setMenuWithDetail(updatedMenu);
  // };
  
  // const handleDislikeClick = (dayMenu) => {
  //   const updatedMenu = [...menuWithDetail];
  //   const dayIndex = updatedMenu.findIndex((day) => day === dayMenu);
  
  //   updatedMenu[dayIndex].eval.dislike = !dayMenu.eval.dislike;
  //   setMenuWithDetail(updatedMenu);
  // };


  return (
    <>
    <Grid sx={{ flexGrow: 1, padding: 3 }} container rowSpacing={2}> 
    <ArrowUpwardIcon sx={{
        backgroundColor: "rgba(0,0,0,0.8)",
        color: "white",
        position: "fixed",
        bottom: "40px",
        right: "40px",
        width: 25,
        height: 25,
        p: 1.5
      }}
    onClick={scrollToTop}/>
    <TableContainer component={Paper}>
      
        {menuWithDetail.map((row) => (
          <React.Fragment key={row.date}>
            <br/>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow id={row.date+'-'+tabIndex} key={row.date}>
              {/* <StyledTableCell>
                  <Box sx={{ fontSize: "30px", background: "lightgray", padding: "8px" }}>{row.date}</Box>
                </TableCell> */}
                <StyledTableCell sx={{ fontSize: "30px" }} align="center" id={row.date} colSpan={2}>
                  {row.date}<br/>
                </StyledTableCell>

                <StyledTableCell align="center">レシピ名</StyledTableCell>
                {/* <TableCell align="right">食材名</TableCell>
                  <TableCell align="right">分量&nbsp;(g)</TableCell> */}
                <StyledTableCell align="right">エネルギー&nbsp;(kcal)</StyledTableCell>
                <StyledTableCell align="right">たんぱく質&nbsp;(g)</StyledTableCell>
                <StyledTableCell align="right">脂質&nbsp;(g)</StyledTableCell>
                <StyledTableCell align="right">ナトリウム&nbsp;(mg)</StyledTableCell>
                <StyledTableCell align="right">費用&nbsp;(円)</StyledTableCell>
                {/* <TableCell align="right">コマンド</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {row.menu.map((recipe) => (
                <Row key={recipe.title} recipe={recipe}/>
              ))}
              <TableRow>
              <TableCell></TableCell>
              {/*ここ*/}
              <TableCell>
                <IconButton
                  aria-label="like"
                  size="small"
                  onClick={() => handleGoodClick(row)}
                  // color={row.eval.like ? "primary" : "default"}
                >
                  <SentimentVerySatisfiedIcon/>
                </IconButton>
                <IconButton
                  aria-label="dislike"
                  size="small"
                  onClick={() => handleBadClick(row)}
                  // color={row.eval.dislike ? "primary" : "default"}
                >
                  <SentimentVeryDissatisfiedIcon/>
                </IconButton>
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                牛乳
              </TableCell>
              <TableCell align="right">126</TableCell>
              <TableCell align="right">6.8</TableCell>
              <TableCell align="right">7.8</TableCell>
              <TableCell align="right">84</TableCell>
              <TableCell align="right">58</TableCell>
              </TableRow>
              <TableRow sx={{backgroundColor: "rgba(0, 0, 0, 0.1)"}}>
              <TableCell/>
              <TableCell>
                <Button onClick={() => handleRowClick(row)}>この献立を採用</Button>
                {/* <Button>再推薦</Button> */}
              </TableCell>
              <TableCell component="th" scope="row" align="right">
                <b>合計</b>
              </TableCell>
              <TableCell align="right">{row.overview.energy.toFixed(1)}</TableCell>
              <TableCell align="right">{row.overview.protein.toFixed(1)}</TableCell>
              <TableCell align="right">{row.overview.lipid.toFixed(1)}</TableCell>
              <TableCell align="right">{row.overview.sodium.toFixed(1)}</TableCell>
              <TableCell align="right">{row.overview.cost.toFixed(1)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          
          </React.Fragment>
        ))}
    </TableContainer>

    <AcceptDialog
      open={isDetailsDialogOpen}
      handleClose={handleCloseDetailsDialog}
      recipe={selectedRecipe}
      data={selectedRecipe}
      user={user}
    />

    <GoodDialog
      open={isGoodDialogOpen}
      handleClose={handleCloseGoodDialog}
      recipe={selectedRecipe}
      data={selectedRecipe}
      user={user}
    />

    <BadDialog
      open={isBadDialogOpen}
      handleClose={handleCloseBadDialog}
      recipe={selectedRecipe}
      data={selectedRecipe}
      user={user}
    />

    
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
            <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{pt: 3}}>
                <Grid item>
                    <Item>費用の合計</Item>
                </Grid>
                <Grid item>
                    <Typography sx={{ width: '15ch'}}>
                        {sum_cost.toFixed(1)} 円
                    </Typography>
                </Grid>
            </Grid>
        </Grid>    
    </Grid>
    <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{pt: 10}}>
      <Grid item>
            <Paper variant="outlined" sx={{p: 3}}>
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
                <Grid item>
                    <Typography>
                        理想値の設定（1日分）
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ padding: 1 }}>
                    <Grid item>   
                        <Grid container sx={{ paddingBottom: 0.5 }} justifyContent="center" alignItems="center" spacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item>
                                <Grid container justifyContent="center" alignItems="center" spacing={3} >
                                    <Grid item xs={6}>
                                        <ItemNutrition>エネルギー</ItemNutrition>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={{ width: '15ch'}}>
                                            {
                                                input["nutritions"]["energy"] ? (
                                                <Typography sx={{ width: '15ch' }}>
                                                {input["nutritions"]["energy"].toFixed(1)} kcal
                                                </Typography>
                                            ) : null
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container justifyContent="center" alignItems="center" spacing={3} >
                                    <Grid item xs={6}>
                                        <ItemNutrition>脂質</ItemNutrition>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={{ width: '15ch'}}>
                                        {
                                            input["nutritions"]["fat"] ? (
                                                <Typography sx={{ width: '15ch' }}>
                                                {input["nutritions"]["fat"].toFixed(1)} g
                                                </Typography>
                                            ) : null
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>   
                        <Grid container justifyContent="center" alignItems="center" spacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item>
                                <Grid container justifyContent="center" alignItems="center" spacing={3} >
                                    <Grid item xs={6}>
                                        <ItemNutrition>たんぱく質</ItemNutrition>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={{ width: '15ch'}}>
                                            {
                                            input["nutritions"]["protein"] ? (
                                                <Typography sx={{ width: '15ch' }}>
                                                {input["nutritions"]["protein"].toFixed(1)} g
                                                </Typography>
                                            ) : null
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container justifyContent="center" alignItems="center" spacing={3} >
                                    <Grid item xs={6}>
                                        <ItemNutrition>ナトリウム</ItemNutrition>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Typography sx={{ width: '15ch'}}>
                                        {
                                        input["nutritions"]["sodium"] ? (
                                            <Typography sx={{ width: '15ch' }}>
                                            {input["nutritions"]["sodium"].toFixed(1)} mg
                                            </Typography>
                                        ) : null
                                        }
                                    </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>   
                        <Grid container justifyContent="center" alignItems="center" spacing={{ xs: 1, sm: 2, md: 3 }} sx={{pt: 2}}>
                            <Grid item>
                                <Grid container justifyContent="center" alignItems="center" spacing={3} >
                                    <Grid item xs={6}>
                                        <Item>費用</Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={{ width: '15ch'}}>
                                          {input["cost_day"].toFixed(1)} 円
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
               
            </Paper>
            </Grid>
            </Grid>
            
</Grid>
</>
  );
}