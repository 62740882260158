// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// // import { getAnalytics } from "firebase/analytics";
// import { getAuth } from "firebase/auth";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyAEF-pTDbLIp4o-yIvzIMS3OCajQK41e5M",
//   authDomain: "fastapi-mitou-school-auth.firebaseapp.com",
//   projectId: "fastapi-mitou-school-auth",
//   storageBucket: "fastapi-mitou-school-auth.appspot.com",
//   messagingSenderId: "40786797631",
//   appId: "1:40786797631:web:abc7e6610024998a27448a",
//   measurementId: "G-DJJ3XFVG3N"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// // const analytics = getAnalytics(app);

// export const auth = getAuth(app);

// // https://reffect.co.jp/react/react-firebase-auth/

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAyTSlHjaXOEAykampuKKfRIPd9bepVJk0",
  authDomain: "menu-recommend-1dc4d.firebaseapp.com",
  projectId: "menu-recommend-1dc4d",
  storageBucket: "menu-recommend-1dc4d.appspot.com",
  messagingSenderId: "161309539089",
  appId: "1:161309539089:web:22a3a0fa76af61f951aab0",
  measurementId: "G-C43Z6JYKM3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const auth = getAuth(app);