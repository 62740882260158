import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { spacing } from '@mui/system';
import SendIcon from '@mui/icons-material/Send';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
// import { DatePicker } from '@mui/x-date-picker';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-picker/DatePicker';
// import { DatePicker } from '@mui/x-date-picker-pro';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/ja';
import ja from 'date-fns/locale/ja'
import jaLocale from 'date-fns/locale/ja'
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Navigate, useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import {
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    getAuth,
    sendEmailVerification
  } from "firebase/auth";
  import { auth } from "../../firebase.js";
  import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';


dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Tokyo");

const currentYear = new Date().getFullYear();
const years = Array.from({ length: 100 }, (_, index) => currentYear - index);
const months = Array.from({ length: 12 }, (_, index) => index + 1);

// const api_url = "http://localhost:8000/"
const api_url = "https://api.menu-recommend.com/"

const nutritions = [ //要変更
    {
        value: '0',
        label: 'カスタム',
        energy: undefined, //kcal
        protein: undefined, //g
        lipid: undefined,
        sodium: undefined, //g未満
        calcium: undefined, //mg 目標値
        iron: undefined, //mg
        vitaminA: undefined, //μg
        vitaminB1: undefined, //mg
        vitaminB2: undefined, //mg
        vitaminC: undefined, //mg
        dietary_fiber: undefined //g
    },
    // {
    //     value: '67',
    //     label: '児童（6歳～7歳）',
    //     energy: 560, //kcal
    //     protein: 16, //g
    //     lipid: undefined,
    //     sodium: 2, //g未満
    //     calcium: 320, //mg 目標値
    //     iron: 3, //mg
    //     vitaminA: 130, //μg
    //     vitaminB1: 0.4, //mg
    //     vitaminB2: 0.4, //mg
    //     vitaminC: 20, //mg
    //     dietary_fiber: 5.5 //g
    // },
    {
        value: '89',
        label: '児童（8歳～9歳)',
        energy: 650, //kcal
        protein: 30, //g
        lipid: 18,
        sodium: 2000, //mg未満
        calcium: 380, //mg 目標値
        iron: 3, //mg
        vitaminA: 140, //μg
        vitaminB1: 0.4, //mg
        vitaminB2: 0.5, //mg
        vitaminC: 23, //mg
        dietary_fiber: 6.0 //g
    },
    // {
    //     value: '1011',
    //     label: '児童（10歳～11歳)',
    //     energy: 770, //kcal
    //     protein: 25, //g
    //     lipid: undefined,
    //     sodium: 3, //g未満
    //     calcium: 480, //mg 目標値
    //     iron: 4, //mg
    //     vitaminA: 170, //μg
    //     vitaminB1: 0.5, //mg
    //     vitaminB2: 0.5, //mg
    //     vitaminC: 26, //mg
    //     dietary_fiber: 6.5 //g
    // },
    // {
    //     value: '1214',
    //     label: '児童（12歳～14歳)',
    //     energy: 850, //kcal
    //     protein: 28, //g
    //     lipid: undefined,
    //     sodium: 3, //g未満
    //     calcium: 470, //mg 目標値
    //     iron: 4, //mg
    //     vitaminA: 210, //μg
    //     vitaminB1: 0.6, //mg
    //     vitaminB2: 0.6, //mg
    //     vitaminC: 33, //mg
    //     dietary_fiber: 7.5 //g
    // },
  ];

  const ToggleContent = ({ children, title }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleContent = () => {
      setIsOpen(!isOpen);
    };
  
    return (
      <div>
        <div onClick={toggleContent} style={{ cursor: 'pointer' }}>
          {isOpen ? '▼' : '▶'} {title}
        </div>
        {isOpen && <div>{children}</div>}
      </div>
    );
  };

const theme = createTheme();

const MyForm = () => {
    // const day_num = 5; //TO DO
    const defaultCost = 300;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    let d = new Date();
    const [error_message, setErrorMessage] = useState("");
    const [selectedYear, setSelectedYear] = useState(d.getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(d.getMonth() + 1);
    
    const [energy, setEnergy] = useState(nutritions[1]['energy']);
    const [protein, setProtein] = useState(nutritions[1]['protein']);
    const [lipid, setLipid] = useState(nutritions[1]['lipid']);
    const [sodium, setSodium] = useState(nutritions[1]['sodium']);
    const [costDay, setCostDay] = useState(defaultCost);
    const [useYokosuka, setUseYokosuka] = useState(true);
    const [requestBody, setRequestBody] = useState({});

    // console.log(energy, protein, lipid, sodium)

    const [jobId, setJobId] = useState(null);
    const [jobStatus, setJobStatus] = useState(null);

    const [user, setUser] = useState(null);

    const handleCheckboxChange = () => {
    // チェックボックスの状態をトグルする
        setUseYokosuka((prev) => !prev);
    };
    // const auth = getAuth();
    // const user = auth.currentUser;

    useEffect(() => {
        const fetchData = async () => {
            onAuthStateChanged(auth, async (currentUser) => {
                setUser(currentUser);
                if(currentUser){
                    const response = await fetch(`${api_url}school/user`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${currentUser.accessToken}`,
                        }
                    });
        
                    const result = await response.json();
                    // console.log(result);
                    setUseYokosuka(result.yokosuka);
                    // console.log(useYokosuka)
                }
            });
        };
    
        fetchData();
    }, []);

    useEffect(() => {
        const checkJobStatus = async () => {
            let url = user ? `${api_url}school/` : `${api_url}school_guest/`
            let headers = user ? {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.accessToken}`
            }:
            {'Content-Type': 'application/json',}
            try {
                const response = await fetch(`${url}${jobId}/`, {
                    method: 'GET',
                    headers: headers,
                });
                const result = await response.json();
                setJobStatus(result.status);
    
                if (result.status === "done") {
                    navigate('/school/result-calendar', { state: { 'body': result.result, 'year': selectedYear, 'month': selectedMonth, 'input': requestBody } });
                }
    
                if (result.status === "error") {
                    console.log("APIエラー(check)");
                    setErrorMessage("エラーが起きました。もう一度時間を置いてお試しください。");
                    setLoading(false);
                }
            } catch (error) {
                console.error("エラーが発生しました: ", error);
                setJobId(null);
                // Handle errors as needed
            }
        };
    
        // console.log(jobId);
        // console.log(jobStatus);
    
        if (jobId) {
            const interval = setInterval(() => {
                checkJobStatus();
            }, 5000);
    
            return () => clearInterval(interval);
        }
    }, [jobId]);
    


    const startSchoolJob = async () => {
        let url = user ? `${api_url}school/` : `${api_url}school_guest/`
        let headers = user ? {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.accessToken}`
        }:
        {'Content-Type': 'application/json',}
        // console.log(url)

        let request_body={
            // "day_num" : Number(day_num),
            "year" : Number(selectedYear),
            "month" : Number(selectedMonth),
            nutritions: {
                "energy": Number(energy), //650,
                "protein": Number(protein), //30,
                "fat": Number(lipid),//18,
                "sodium": Number(sodium)//1000
            },
            "cost_day" : Number(costDay),
            "yokosuka": useYokosuka,
        }
        setRequestBody(request_body)
        try{
        // const response = await fetch('https://api.menu-recommend.com/school/', {
        const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(request_body),
        });
    
            const result = await response.json();
            setJobId(result.job_id);
            setLoading(true);
        }catch{
            console.log("apiエラー(start)")
            setErrorMessage("エラーが起きました。もう一度時間を置いてお試しください。")
            setLoading(false); 
            setJobId(null);
        }

    }

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: 30,
        width: 100,
        lineHeight: '30px',
        backgroundColor: "#F5DCDE",
    }));

    const ItemSmall = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        fontSize: '12px',
        textAlign: 'center',
        display: 'table-cell',
        verticalAlign: 'middle',
        // verticalAlign: 'super',
        color: theme.palette.text.secondary,
        height: 20,
        width: 80,
        // lineHeight: '30px',
        backgroundColor: "#ffd4b1",
    }));

    const SelectedItem = styled(Paper)(({ theme, textLength }) => ({
        ...theme.typography.body2,
        fontSize: '12px',
        textAlign: 'center',
        // display: 'table-cell',
        verticalAlign: 'middle',
        display: 'flex', // displayをflexに変更
        alignItems: 'center', // 縦方向の中央揃えを追加
        justifyContent: 'center', // 横方向の中央揃えを追加
        // verticalAlign: 'super',
        color: theme.palette.text.secondary,
        height: 20,
        width: `${textLength*3}ch`, // 幅を動的に設定(良かったので3倍で固定)
        // lineHeight: '30px',
        backgroundColor: "#ffffff",
      }));
    
    // 栄養素用
    const [nutritionCategory, setNutritionCategory] = useState('0');

    // // 食材用
    // const [selectedFood, setSelectedFood] = useState([]);
    // // console.log("食材" + JSON.stringify(selectedFood,null,'\t'))

    // //指定メニュー用
    // const [selectedRecipe, setSelectedRecipe] = useState([]);
    // // console.log("指定メニュー" + JSON.stringify(selectedRecipe,null,'\t'))

    // // 日付用  
    // const [selectedDate, setSelectedDate] = useState([]);
    // // console.log("指定日" + JSON.stringify(selectedDate,null,'\t'))

    
    
    // const [selectedFood, setSelectedFood] = useState([]);

    
    // console.log(nutritionCategory);
    const [monthAttention, setMonthAttention] = useState('');
    const handleChange = (event) => {
        let tmpMonth = event.target.value;
        if(tmpMonth !== 7){
            setMonthAttention("試用版のため7月の献立表が表示されます");
        }else{
            setMonthAttention("");
        }
    };
    
    return (
        <>
        <ThemeProvider theme={theme}>
        <Grid sx={{ flexGrow: 1, padding: 5 }} container justifyContent="center" alignItems="center" spacing={2} rowSpacing={2}> 
            <Grid item xs={12} sx={{padding: 0.5 }}>
                <Grid container justifyContent="center" alignItems="center" spacing={1}>
                <Grid item>
            <Select
              labelId="year-label"
              id="year-selector"
              value={selectedYear}
              label="年"
              onChange={(e) => setSelectedYear(e.target.value)}
            >
              <MenuItem value="">選択してください</MenuItem>
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item><InputLabel id="year-label">年</InputLabel></Grid>            
          <Grid item>
            <Select
              labelId="month-label"
              id="month-selector"
              value={selectedMonth}
              label="月"
              onChange={(e) => setSelectedMonth(e.target.value)}
            >
              <MenuItem value="">選択してください</MenuItem>
              {months.map((month) => (
                <MenuItem key={month} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {/* </Grid> */}
          <Grid item><InputLabel id="month-label">月</InputLabel></Grid>
          {/* </Grid> */}
                    
                </Grid>
                
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" spacing={3} >
                    <Typography variant="body2" component="h2" sx={{p:2, color: "gray"}}>
                        {monthAttention}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" spacing={3} >
                    <Grid item>
                        <Item>栄養素(1日分)</Item>
                    </Grid>
                    <Grid item> 
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Select"
                            defaultValue={nutritions[1]['value']}
                            size="small"
                            // helperText="Please select your currency"
                            sx={{ width: '20ch' }}
                            onChange={(event) => setNutritionCategory(event.target.value)}
                            >
                            {nutritions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </Grid>                
            <Grid item xs={12}>
                <Grid item>   
                    <Grid container sx={{ paddingBottom: 0.5 }} justifyContent="center" alignItems="center" spacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item>
                            <Grid container justifyContent="center" alignItems="center" spacing={3} >
                                <Grid item >
                                    <ItemSmall>エネルギー</ItemSmall>
                                </Grid>
                                <Grid item>
                                    <TextField id="nutrition_energy"
                                        // value={nutritions.find(el => el.value === nutritionCategory)['energy']}
                                        size="small"
                                        sx={{ width: '15ch'}}
                                        defaultValue={energy}
                                        InputProps={{
                                            inputMode: 'numeric', pattern: '[0-9]*',
                                            endAdornment: <InputAdornment position="end">kcal</InputAdornment>,
                                            style: { height: "30px"},
                                        }}
                                        onChange={(event)=>setEnergy(event.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container justifyContent="center" alignItems="center" spacing={3} >
                                <Grid item>
                                    <ItemSmall>脂質</ItemSmall>
                                </Grid>
                                <Grid item>
                                    <TextField id="hiyou"
                                        // value={nutritions.find(el => el.value === nutritionCategory)['lipid']}
                                        size="small"
                                        sx={{ width: '15ch'}}
                                        defaultValue={lipid}
                                        InputProps={{
                                            inputMode: 'numeric', pattern: '[0-9]*',
                                            endAdornment: <InputAdornment position="end">g</InputAdornment>,
                                            style: { height: "30px"},
                                        }}
                                        onChange={(event)=>setLipid(event.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>   
                    <Grid container justifyContent="center" alignItems="center" spacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item>
                            <Grid container justifyContent="center" alignItems="center" spacing={3} >
                                <Grid item>
                                    <ItemSmall>たんぱく質</ItemSmall>
                                </Grid>
                                <Grid item>
                                    <TextField id="hiyou"
                                        // value={nutritions.find(el => el.value === nutritionCategory)['protein']}
                                        size="small"
                                        sx={{ width: '15ch'}}
                                        defaultValue={protein}
                                        InputProps={{
                                            inputMode: 'numeric', pattern: '[0-9]*',
                                            endAdornment: <InputAdornment position="end">g</InputAdornment>,
                                            style: { height: "30px"},
                                        }}
                                        onChange={(event)=>setProtein(event.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container justifyContent="center" alignItems="center" spacing={3} >
                                <Grid item>
                                    <ItemSmall>ナトリウム</ItemSmall>
                                </Grid>
                                <Grid item>
                                    <TextField id="hiyou"
                                        // value={nutritions.find(el => el.value === nutritionCategory)['sodium']}
                                        size="small"
                                        sx={{ width: '15ch'}}
                                        defaultValue={sodium}
                                        InputProps={{
                                            inputMode: 'numeric', pattern: '[0-9]*',
                                            endAdornment: <InputAdornment position="end">mg 未満</InputAdornment>,
                                            style: { height: "30px"},
                                        }}
                                        onChange={(event)=>setSodium(event.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" spacing={3}  sx={{pt: 3}}>
                    <Grid item>
                        <Item>費用(1日分)</Item>
                    </Grid>
                    <Grid item>
                        <TextField id="hiyou"
                            size="small"
                            defaultValue={costDay}
                            InputProps={{
                                inputMode: 'numeric', pattern: '[0-9]*',
                                endAdornment: <InputAdornment position="end">円</InputAdornment>,
                            }}
                            sx={{ width: '20ch' }}
                            onChange={(event)=>setCostDay(event.target.value)}
                            />
                    </Grid>
                </Grid>
                {user !== null && (
                <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{pt: 1}}>
                    <Grid item>
                    {/* <Typography variant="body" sx={{color: "black"}}>
                        × 5 (日分) = {sumCost} 円
                    </Typography> */}
                    
                    <FormControlLabel
                        control={ <Checkbox checked={useYokosuka} disabled={user === null} onClick={handleCheckboxChange}/>}
                        label="横須賀のオープンデータのレシピを使用する"
                    />
                    </Grid>
                </Grid>
                )}
            </Grid>    
            <Grid item xs={12}>   
                <Grid container sx={{padding: 3}} justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item>
                    {loading? (<>
                        <Grid container direction="column" alignItems="center" spacing={1}>
                            <Grid item>
                                <CircularProgress color="success" />
                            </Grid>
                            <Grid item>
                            <Grid item>
                                <Typography sx={{ pt: 3, whiteSpace: "pre-wrap", textAlign: "center" }}>
                                    {user
                                        ? 'Jobを登録しました！\n（1ヶ月分の推薦に5分ほどかかります。このままお待ちいただくか、後ほど「過去の実行結果」をご参照ください。)'
                                        : '5日分の献立を生成します...1分ほどお待ちください\n（状況によって時間が前後する場合があります）'}
                                </Typography>
                            </Grid>

                            </Grid>
                        </Grid>
                    </>):(
                    <>
                    <Grid container direction="column" alignItems="center" spacing={1}>
                        <Grid item>
                            <Button onClick={startSchoolJob} variant="outlined" endIcon={<SendIcon />}>献立表作成</Button>
                        </Grid>
                        <Grid item>
                            <Typography>
                                {error_message}
                            </Typography>
                        </Grid>
                    </Grid>
                    </>
                    )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{pl:5, pt: 5}}>
            <ToggleContent title="このアプリケーションについて">
                <Grid container direction="column" alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{p: 2}}>
                        このアプリケーションでは、学校給食の献立をさまざまな条件に基づいてシミュレーテッドアニーリングで作成します。<br />
                        デフォルトでは、
                        <a href="https://www.city.yokosuka.kanagawa.jp/8345/kyuushoku/kyuusyoku-menu-open.html">
                        横須賀市の小学校給食献立表
                        </a>にあるレシピデータを使って、献立を作成します。<br/>
                        献立は、次の8つの条件に基づいて作成されます。
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper sx={{p: 3}}>
                        <Typography variant="body1">
                        <b>① 献立の構成 (主食と主菜が1品、副菜、汁物、デザートが0-1品)</b><br />
                        <b>② 献立の栄養素の合計値を理想値に近づける</b><br />
                        <b>③ 費用を理想値に近づける</b><br />
                        <b>④ 同じレシピが月に2回以上出ないようにする</b><br/><br/>
                            ⑤ 1日分の献立の中での料理のジャンルをそろえる<br/>
                            ⑥ 主食に合うレシピを推薦する<br />
                            ⑦ 似ている献立(使っている食材が同じ、料理のジャンルが同じ)が隣りあわないようにする<br />
                            ⑧ ユーザからの評価(悪いと判断されたレシピの組み合わせは出さないようにする)
                        </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{pt: 2}}>
                        <b>太字</b>で示されている項目が、必ず満たしたい強い制約で、そのほかはなるべく満たしたい弱い制約です。
                        栄養素の理想値については、文部科学省が定めている
                        <a href="https://www.mext.go.jp/content/20210212-mxt_kenshoku-100003357_2.pdf">児童又は生徒1人1回当たりの学校給食摂取基準</a>から
                        「児童（8歳～9歳）」の値を設定し、他の学年の児童または生徒については、量を定数倍することで調整をする想定で最適化をしています。また、費用の理想値は、1食分200-300円程度を想定しています。
                        <br />実際の推薦では、制約が満たされないこともありますが、何回か推薦を試すことで満たされることもあります。<br /><br />
                        ユーザ登録をすると、オリジナルレシピの追加や、献立管理などができます。自分だけの献立表を作成してみてください。
                        </Typography>
                    </Grid>
                </Grid>
            </ToggleContent>
            </Grid>
        </Grid>
        </ThemeProvider>
        </>
    );
}
export default MyForm;
