/* eslint-disable import/first */
import React from "react";
import { Link } from "react-router-dom";
import Navigation from '../contents/Navigation'
import Grid from '@mui/material/Grid';
import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';
import { useParams, useSearchParams } from 'react-router-dom';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  getAuth,
  sendEmailVerification
} from "firebase/auth";
import { auth } from "../../firebase.js";

const category_name = ["主菜", "副菜", "主食", "汁物", "デザート"]
const category_name_en = ["main", "side", "staple", "soup", "dessert"]
const category_color = ["#ffeddb", "#edffdb", "#ffdbed", "#dbedff", "#ffffdb"]

const genre_name = ["和風", "洋風", "中華風", "韓国風", "エスニック"]
const genre_color = ["#C66600", "#3366ff", "#ff7f7f", "#387d39", "#6f5436"]

// const api_url = "http://localhost:8000/"
const api_url = "https://api.menu-recommend.com/"

// import Data from '../recipes_0725_edit_costari_seasonari.json';
import Data from "../one_menu.json";

Data = Data[0]

// console.log(Data)

const nutrition_unit = {
    "エネルギー": "kcal", "たんぱく質": "g", "脂質": "g", "ナトリウム": "mg"
}
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 30,
  width: 100,
  lineHeight: '30px',
  backgroundColor: "#F5DCDE",
}));

const ItemNutrition = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 30,
  width: 100,
  lineHeight: '30px',
  backgroundColor: "#ffd4b1",
}));

  const RecipeTable = ({ data, onRowClick, onEditClick, onDeleteClick, year, month, day}) => {
    // ソートするための順序マップ
    const categoryOrder = {
      2: 0,
      0: 1,
      1: 2,
      3: 3,
      4: 4
    };

    data.sort((a, b) => categoryOrder[a.recipe.category] - categoryOrder[b.recipe.category]);
    // console.log(data)

    // // 日付ごとのデータをソート
    // for (const key in dataDictionary) {
    //   if (dataDictionary.hasOwnProperty(key)) {
    //     dataDictionary[key].sort((a, b) => categoryOrder[a.category] - categoryOrder[b.category]);
    //   }
    // }
    const [selectedRecipe, setSelectedRecipe] = useState([]);
      const [isDetailsDialogOpen, setDetailsDialogOpen] = useState(false);
      const handleTitleClick = (recipe) => {
        // レシピの詳細情報をセットしてダイアログを開く
        setSelectedRecipe(recipe);
        // console.log(recipe);
        setDetailsDialogOpen(true);
      };
      const handleCloseDetailsDialog = () => {
        // ダイアログを閉じる
        setDetailsDialogOpen(false);
      };
    return (
    <>
      <TableContainer component={Paper} sx={{p: 3}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Genre</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                <Button
                component="button"
                variant="body2"
                onClick={() => handleTitleClick(item.recipe)}
                >
                    {item.recipe.title}
                </Button>
                </TableCell>
                <TableCell>
                  {<Chip
                    label={category_name[item.recipe.category]}
                    sx={{
                      backgroundColor: category_color[item.recipe.category],
                    }}
                  />}
                </TableCell>
                <TableCell>
                  {<Chip
                    label={genre_name[item.recipe.genre]}
                    sx={{
                      backgroundColor: genre_color[item.recipe.genre],
                      color: "#ffffff"
                    }}
                  />}
                  </TableCell>
                <TableCell>
                  <Button onClick={() => onEditClick(item)} component={Link} to={`/school/edit-menu?year=${year}&month=${month}&day=${day}&method=edit&id=${item.id}`}>
                    <ModeEditIcon/>編集 
                  </Button>
                  <Button onClick={() => onDeleteClick(item)}><DeleteIcon/>削除</Button>
                </TableCell>
              </TableRow>
            ))}

              <TableRow>
              <TableCell component="th" scope="row" align="center">
                牛乳
              </TableCell>
              {/* <TableCell align="right">126</TableCell>
              <TableCell align="right">6.8</TableCell>
              <TableCell align="right">7.8</TableCell>
              <TableCell align="right">84</TableCell> */}
              {/* <TableCell align="right">0</TableCell> */}
              </TableRow>

            <TableRow>
              <TableCell colSpan={4}>
                <Button component={Link} to={`/school/edit-menu?year=${year}&month=${month}&day=${day}&method=add`}>
                  <AddCircleOutlineIcon/>レシピを追加する
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <RecipeDetailsDialog
      open={isDetailsDialogOpen}
      handleClose={handleCloseDetailsDialog}
      recipe={selectedRecipe}
    />

    </>
    );
  };

  const MenuInfo = ({ data }) => {
    let energy = 126;
    let protein = 6.8;
    let fat = 7.8;
    let sodium = 84;
  
    // dataがnullまたはundefinedでないことを確認する
    if (data && data.length > 0) {
      data.forEach((item) => {
        // item.recipeがnullまたはundefinedでないことを確認する
        if (item.recipe && item.recipe.nutritions) {
          energy += item.recipe.nutritions.エネルギー || 0;
          protein += item.recipe.nutritions.たんぱく質 || 0;
          fat += item.recipe.nutritions.脂質 || 0;
          sodium += item.recipe.nutritions.ナトリウム || 0;
        }
      });
    }
    return (
      <Grid item xs={12} sx={{pt: 3}}>
                <Grid container justifyContent="center" alignItems="center" spacing={3} >
                    <Grid item>
                        <Typography>
                            合計
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ padding: 1 }}>
                    <Grid item>   
                        <Grid container sx={{ paddingBottom: 0.5 }} justifyContent="center" alignItems="center" spacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item>
                                <Grid container justifyContent="center" alignItems="center" spacing={3} >
                                    <Grid item>
                                        <ItemNutrition>エネルギー</ItemNutrition>
                                    </Grid>
                                    <Grid item>
                                        <Typography sx={{ width: '15ch'}}>
                                            {energy.toFixed(1)} kcal
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container justifyContent="center" alignItems="center" spacing={3} >
                                    <Grid item>
                                        <ItemNutrition>脂質</ItemNutrition>
                                    </Grid>
                                    <Grid item>
                                        <Typography sx={{ width: '15ch'}}>
                                            {fat.toFixed(1)} g
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>   
                        <Grid container justifyContent="center" alignItems="center" spacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item>
                                <Grid container justifyContent="center" alignItems="center" spacing={3} >
                                    <Grid item>
                                        <ItemNutrition>たんぱく質</ItemNutrition>
                                    </Grid>
                                    <Grid item>
                                        <Typography sx={{ width: '15ch'}}>
                                            {protein.toFixed(1)} g
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container justifyContent="center" alignItems="center" spacing={3} >
                                    <Grid item>
                                        <ItemNutrition>ナトリウム</ItemNutrition>
                                    </Grid>
                                    <Grid item>
                                    <Typography sx={{ width: '15ch'}}>
                                        {sodium.toFixed(1)} mg
                                    </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" spacing={3} >
                        <Grid item>
                            <Item>費用</Item>
                        </Grid>
                        <Grid item>
                            <Typography sx={{ width: '15ch'}}>
                                {sum_cost.toFixed(1)} 円
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>     */}
            </Grid>
    );
  };
  
    // ソートするための順序マップ

  const IngredientsTable = ({ ingredients }) => {
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Energy</TableCell>
              <TableCell align="right">Protein</TableCell>
              <TableCell align="right">Lipid</TableCell>
              <TableCell align="right">Sodium</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ingredients.map((ingredient, index) => (
              <TableRow key={index}>
                <TableCell>{ingredient.name}</TableCell>
                <TableCell align="right">{ingredient.amount}</TableCell>
                <TableCell align="right">{ingredient.energy}</TableCell>
                <TableCell align="right">{ingredient.protein}</TableCell>
                <TableCell align="right">{ingredient.lipid}</TableCell>
                <TableCell align="right">{ingredient.sodium}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const RecipeDetailsCard = ({ recipe }) => {
    return (
      <Card>
        <CardContent>
          <List>
            <ListItem>
              <ListItemText primary="Title" secondary={recipe.title} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Category"
                secondary={
                  <Chip
                    label={category_name[recipe.category]}
                    sx={{
                      backgroundColor: category_color[recipe.category],
                      edgecolor: "#000000"
                    }}
                  />
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Genre"
                secondary={
                  <Chip
                    label={genre_name[recipe.genre]}
                    sx={{
                      backgroundColor: genre_color[recipe.genre],
                      color: "#ffffff"
                    }}
                  />
                }
              />
            </ListItem>
            {/* 他の詳細情報も同様に表示 */}
            <ListItem>
              <ListItemText
                primary="Nutritions"
                secondary={
                  <List>
                    {Object.entries(recipe.nutritions).map(([key, value]) => (
                      <ListItem key={key}>
                        <ListItemText primary={key} secondary={value} />
                      </ListItem>
                    ))}
                  </List>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Ingredients"
                secondary={
                  <IngredientsTable ingredients={recipe.ingredients} />
                }
              />
            </ListItem>
            {/* <ListItem>
              <ListItemText primary="Cost" secondary={recipe.cost} />
            </ListItem> */}
          </List>
        </CardContent>
      </Card>
    );
  };

  const RecipeDetailsDialog = ({ open, handleClose, recipe}) => {
      return (
        <Dialog open={open} onClose={handleClose} maxWidth="md">
          <DialogTitle>{recipe.title} の詳細</DialogTitle>
          <DialogContent>
           <RecipeDetailsCard recipe={recipe} />
          </DialogContent>
        </Dialog>
      );
    };

const OtherComponent = ({ selectedItem }) => {
    return (
      <div>
        <h2>Details</h2>
        <p>Title: {selectedItem.recipe.title}</p>
        <p>Category: {selectedItem.recipe.category}</p>
        <p>Genre: {selectedItem.recipe.genre}</p>
        {/* 他にも表示したいデータがあればここに追加 */}
      </div>
    );
  };


const MenuTable = () => {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [recommendOpen, setRecommendOpen] = useState(false);

  const year = searchParams.get('year');
  const month = searchParams.get('month');
  const day = searchParams.get('day');
  // console.log(year, month, day);
  const [user, setUser] = useState(null);

  const fetchData = async (user, year, month, day) => {
    try {
      const response = await fetch(`${api_url}school/menus?year=${year}&month=${month}&day=${day}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${user.accessToken}`
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      // console.log(result);

      setData(result);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
      onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser);
        if(currentUser){
          fetchData(currentUser, year, month, day);
        }
        // console.log(currentUser)
    });
  }, []);
      
      
      
        const handleEditClick = (item) => {
          // 編集ボタンがクリックされたときの処理を追加
          // console.log("Edit clicked for item:", item);
          return <Link to={`/school/edit-menu?year=${year}&month=${month}&day=${day}`}></Link>
          
        };
      
        const handleDeleteClick = async (item) => {
          // 削除ボタンがクリックされたときの処理を追加
            try {
              const response = await fetch(`${api_url}school/menus?id=${item.id}`, {
                method: 'DELETE',
                headers: {
                  'Accept': 'application/json',
                  'Authorization': `Bearer ${user.accessToken}`,
                },
              });
        
              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
        
              // console.log('DELETE request successful', item);
              // ページを再読み込み
              window.location.reload();
            } catch (error) {
              console.error('Error:', error.message);
              // エラーが発生した場合の処理をここに追加できます
            }
        };

        const handleMenuRecommend = () => {
          // Increment the count value by 1
          setRecommendOpen(!recommendOpen);
          // console.log(recommendOpen)
        };

        const Recipe = ({ title, nutritions }) => (
          <div>
            <h3>{title}</h3>
            <p>Nutritions:</p>
            <ul>
              <li>Fat: {nutritions.fat}</li>
              <li>Cost: {nutritions.cost}</li>
              <li>Energy: {nutritions.energy}</li>
              <li>Sodium: {nutritions.sodium}</li>
              <li>Protein: {nutritions.protein}</li>
            </ul>
          </div>
        );
        
        // 1食分の推薦
        const RecipeList = ({ data, year, month, day }) => {
          const categoryOrder = {
            2: 0,
            0: 1,
            1: 2,
            3: 3,
            4: 4,
          };

          const handleMenuAccept = async (menu) => {
            try {
              // Promiseオブジェクトを保持するための配列
              const promises = [];
        
              menu.forEach((recipe) => {
                // console.log(recipe)
                // 各レシピに対してfetchを実行し、Promiseオブジェクトを配列に追加
                promises.push(
                  fetch(`${api_url}school/menus`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Accept': 'application/json',
                      'Authorization': `Bearer ${user.accessToken}`,
                    },
                    body: JSON.stringify({
                      "date": `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`,
                      "recipe_id": recipe.id
                    }),
                  })
                );
              });
        
              // Promiseオブジェクトを並行して実行
              const responses = await Promise.all(promises);
        
              // すべてのリクエストが成功したかどうかを確認
              const allResponsesSuccessful = responses.every(response => response.ok);
        
              if (allResponsesSuccessful) {
                alert('登録しました！');
                // ページを再読み込み
                window.location.reload();
              } else {
                throw new Error('Some POST requests failed');
              }
            } catch (error) {
              console.error('Error:', error.message);
              // エラーが発生した場合の処理をここに追加できます
            }
          };
                
          return (
            <TableContainer component={Paper} sx={{p: 5}}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={300}></TableCell>
                    <TableCell width={300}></TableCell>
                    {/* <TableCell>Nutritions</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.menus.map((row, rowIndex) => {
                    const sortedMenu = row.menu.slice().sort((a, b) => categoryOrder[a.category] - categoryOrder[b.category]);
        
                    return (
                      <React.Fragment key={rowIndex}>
                        <TableRow>
                          <TableCell colSpan={3}>献立案{rowIndex+1}</TableCell>
                        </TableRow>
                        {sortedMenu.map((recipe, index) => (
                          <TableRow key={index}>
                            <TableCell>{category_name[recipe.category]}</TableCell>
                            <TableCell>{recipe.title}</TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell colSpan={3}>
                            <ul>
                              <li>エネルギー: {row.info.energy.toFixed(1)}</li>
                              <li>脂質: {row.info.fat.toFixed(1)}</li>
                              <li>たんぱく質: {row.info.protein.toFixed(1)}</li>
                              <li>ナトリウム: {row.info.sodium.toFixed(1)}</li>
                              <li>費用: {row.info.cost.toFixed(1)}</li>
                            </ul>
                            <Grid container justifyContent="flex-end" sx={{pt: 1, pr: 1}}>
                              <Button onClick={() => handleMenuAccept(row.menu)}>この献立を採用</Button>
                            </Grid>
                          </TableCell>
                          
                        </TableRow>
                        
                      </React.Fragment>
                    );
                  })}
                
                </TableBody>
                
              </Table>
            </TableContainer>
          );
        };
        
        
        

      
        return (
          <>
          <Navigation/>
            <Grid container justifyContent="center"
              alignItems="center">
              <Grid item xs={10}>
            <Typography sx={{ pt: 3, pl: 3}} variant="h5">{year}年 {month}月{day}日 の献立</Typography>

            <RecipeTable
              data={data}
              // onRowClick={handleRowClick}
              onEditClick={handleEditClick}
              onDeleteClick={handleDeleteClick}
              year={year}
              month={month}
              day={day}
            />
            {/* <Button onClick={handleMenuRecommend}>
              1食分の献立を推薦する
            </Button> */}
            <Typography sx={{ pt: 3, pl: 3}} variant="body2">
              牛乳は エネルギー 126kcal  たんぱく質 6.8g  脂質 7.8g  ナトリウム 84mg で換算
            </Typography>
            <MenuInfo data={data}/>
             <Grid container justifyContent="flex-end" sx={{pt: 1, pr: 2}}>
              <Button component={Link} to={`/school/menu-list?year=${year}&month=${month}`}>
                {year}年{month}月の献立表に戻る
              </Button>
             </Grid>
              </Grid>
              {recommendOpen && (
                <Grid item xs={8}>
                  <Typography sx={{ pt: 3, pl: 3}} variant="h5">1食分の献立推薦</Typography>
                  <RecipeList data={Data} year={year} month={month} day={day} />
                </Grid>
              )}
            </Grid>
            </>
        );
      };
export default MenuTable;
