import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Navigate, useNavigate } from "react-router-dom";
import {
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    getAuth,
    sendEmailVerification
  } from "firebase/auth";
import { auth } from "../../firebase.js";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
  
import Navigation from '../contents/Navigation'
import Footer from "../contents/Footer";

// const api_url = "http://localhost:8000/"
const api_url = "https://api.menu-recommend.com/"

const theme = createTheme();

const Jobs = () => {
    const navigate = useNavigate();
    
    const [error_message, setErrorMessage] = useState("");
    const [user, setUser] = useState(null);
    const [jobs, setJobs] = useState([]);

    const getJobs = async (user) => {
        try{
            const response = await fetch(`${api_url}school/jobs`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
                  'Authorization': `Bearer ${user.accessToken}`,
                }
              });
              // console.log(response)
            const result = await response.json();
            // console.log(result)
            setJobs(result);
            return result
        }catch{
            console.log("apiエラー(getjob)")
            setErrorMessage("エラーが起きました。もう一度時間を置いてお試しください。")
            // setLoading(false); 
        }
        
    };

    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            if(currentUser){
                getJobs(currentUser)
            }
        });
    }, []);

    const rows = jobs.map(job => ({
        "created_at": job["created_at"],
        "updated_at": job["updated_at"],
        "month": job["input"]["month"],
        "sum_cost": job["input"]["sum_cost"],
        "day_num": job["input"]["day_num"],
        "status": job["status"]
    }));

    const toCalendar = (index) => {
        // console.log(jobs[index]["result"])
        navigate('/school/result-calendar' , {state: {'body':jobs[index]["result"], 'year':jobs[index]["input"]["year"], 'month':jobs[index]["input"]["month"], 'tabIndex': 0, 'input': jobs[index]["input"]}});
    }

     // スタイルオブジェクトを定義
  const pageStyle = {
    // 通常のページスタイル
    padding: '20px',
    border: '1px solid #ccc',
    // ログインしていない場合、操作を無効にするスタイル
    pointerEvents: user ? 'auto' : 'none',
    opacity: user ? 1 : 0.5, // ログインしていない場合の外観を変更（任意）
  };

  return (
            <>
            <Navigation/>  
            {!user && (
                <Grid sx={{ flexGrow: 1, padding: 2 }} container justifyContent="center" alignItems="center" spacing={2} rowSpacing={2}> 
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{p: 3}}>
                    <Grid item>
                    {/* <Grid item> */}
                        <Typography variant="body">
                            ユーザ登録をすると実行結果が保存・表示されます
                        </Typography>
                    </Grid>
                    </Grid>
                </Grid>
                </Grid>
            )}
            <div style={pageStyle}>
            <Grid sx={{ flexGrow: 1, padding: 2 }} container justifyContent="center" alignItems="center" spacing={2} rowSpacing={2}> 
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{p: 3}}>
                    <Grid item>
                    {/* <Grid item> */}
                        <Typography variant="h4">
                            実行結果一覧
                        </Typography>
                    </Grid>
                    </Grid>
                </Grid>
                </Grid>
            <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="right">発行日時</TableCell>
                <TableCell align="right">更新日時</TableCell>
                <TableCell align="right">ステータス</TableCell>
                <TableCell align="right"></TableCell>
                {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={row.status}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="right">
                    {row["created_at"]}
                  </TableCell>
                  <TableCell align="right">{row.updated_at}</TableCell>
                  <TableCell align="right">{row.status}</TableCell>
                  <TableCell align="right">
                  {row.status === "done" && (
                  <Button variant="outlined" color="primary" onClick={()=>toCalendar(index)}>
                    結果を見る
                  </Button>
                )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </div>
        <Footer/>
    </>
);
}
export default Jobs;
