import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { TopPage } from "./components/pages/TopPage";
import { ResultCalendars } from "./components/pages/ResultCalendar";
import { ResultTables } from "./components/pages/ResultTable";
import { PastMenu } from "./components/pages/PastMenu";
import { RecipeList } from "./components/pages/RecipeList";
import { Portfolio } from "./portfolio.jsx";
import Register from "./components/pages/Register";
import Login from "./components/pages/Login";
import ChangePassword from "./components/pages/ChangePassword";
import UserInfo from "./components/pages/UserInfo";
// import Mypage from "./components/pages/Mypage";
import Jobs from "./components/pages/Jobs"
import MenuList from "./components/pages/MenuList"
import CostList from "./components/pages/CostList"
import MenuTable from "./components/pages/MenuTable"
import EditMenu from "./components/pages/EditMenu"
import AddRecipes from "./components/pages/AddRecipes"
import { Terms } from "./components/pages/Terms"
import { Privacy } from "./components/pages/Privacy"

export const RouterConfig = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Portfolio />} />
        <Route path="school" element={<TopPage />} />
        <Route path="school/result-calendar" element={<ResultCalendars />} />
        <Route path="school/result-table" element={<ResultTables />} />
        <Route path="school/past-menu" element={<PastMenu />} />
        <Route path="school/recipes/*" element={<RecipeList />} />
        <Route path="school/register" element={<Register />} />
        <Route path="school/login" element={<Login />} />
        <Route path="school/change-password" element={<ChangePassword />} />
        <Route path="school/user-info" element={<UserInfo />} />
        <Route path="school/jobs" element={<Jobs />} />
        <Route path="school/menu-list" element={<MenuList />} />
        <Route path="school/cost-list" element={<CostList />} />
        <Route path="school/menu-table" element={<MenuTable />} />
        <Route path="school/edit-menu" element={<EditMenu />} />
        <Route path="school/add-recipes" element={<AddRecipes />} />
        <Route path="school/terms" element={<Terms />} />
        <Route path="school/privacy" element={<Privacy />} />
      {/* <Route path={`/`} element={<Mypage />} /> */}
        {/* <Route path="posts" element={<RecipeList />} /> */}
      </Routes>
    </BrowserRouter>
  );
}