// レシピ一括登録
import Navigation from '../contents/Navigation'
import React from "react";
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  getAuth,
  sendEmailVerification
} from "firebase/auth";
import { auth } from "../../firebase.js";

import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

// const api_url = "http://localhost:8000/"
const api_url = "https://api.menu-recommend.com/"


const FileUploader = ({ onFileUpload }) => {
    const onDrop = (acceptedFiles) => {
      // 選択段階でファイルが受け入れられるか確認
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
  
        // 拡張子がExcelファイルか確認
        if (file.name.endsWith('.xls') || file.name.endsWith('.xlsx')) {
          onFileUpload(file);
        } else {
            alert('無効なファイル形式です。Excelファイルを選択してください。')
          console.error('無効なファイル形式です。Excelファイルを選択してください。');
        }
      }
    };
  
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      accept: '.xls, .xlsx',
      multiple: false, // マルチファイル選択を無効にする
    });
  
    return (
      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>ファイルをここにドロップしてください</p>
        ) : (
          <p>Excelファイルをアップロード</p>
        )}
      </div>
    );
  };
  
  const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
  };

const AddRecipes = () => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });
    }, []);
    const boxStyle = {
        padding: 10,
    };
    const handleFileUpload = async (file) => {
        // ファイルがアップロードされた際の処理
        // console.log('アップロードされたファイル:', file);
        const formData = new FormData();
        formData.append('file', file);

        try {
        const response = await fetch(`${api_url}school/upload`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${user.accessToken}`,
            },
            body: formData,
        });

        if (response.ok) {
            const data = await response.json();
            // console.log('APIへのアップロードが成功:', data);
            alert(`${data.length}件のレシピデータを登録しました！`)
        } else {
            // console.error(response);
            // console.error('APIへのアップロードが失敗:', response.statusText);
            alert(`excelデータの形式が間違っています。(${file.name})`)
        }
        } catch (error) {
            console.error('APIへのアップロードが失敗:', error);
        }
        };

        const pageStyle = {
            // 通常のページスタイル
            padding: '20px',
            border: '1px solid #ccc',
            // ログインしていない場合、操作を無効にするスタイル
            pointerEvents: user ? 'auto' : 'none',
            opacity: user ? 1 : 0.5, // ログインしていない場合の外観を変更（任意）
          };
  return (
    <>
    <Navigation/>
    {!user && (
      <Grid sx={{ flexGrow: 1, padding: 2 }} container justifyContent="center" alignItems="center" spacing={2} rowSpacing={2}> 
        <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{p: 3}}>
            <Grid item>
            {/* <Grid item> */}
                <Typography variant="body">
                    ユーザ登録をすると献立表の作成・管理ができます
                </Typography>
            </Grid>
            </Grid>
        </Grid>
      </Grid>
    )}

    <div style={pageStyle}>
    <Typography variant="h5" align="center" gutterBottom sx={{pt: 3}}>
        レシピデータの一括登録
    </Typography>
    <Box style={boxStyle}>
    <h2>1. Excelフォーマットをダウンロード</h2>
    <Button variant='outlined' component={Link} to={`${api_url}files/sample_recipes_data.xlsx`}>一括追加フォーマットダウンロード(excelファイル)</Button>

    <h2>2. Excelの内容を書き換え</h2>
    <h2>3. Excelファイルのアップロード</h2>
      <FileUploader onFileUpload={handleFileUpload} />
    </Box>
    </div>
    </>

);
}
export default AddRecipes;
