import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import Navigation from '../contents/Navigation'
import Grid from '@mui/material/Grid';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Footer from "../contents/Footer";
import { Navigate, useNavigate } from "react-router-dom";

import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  getAuth,
  sendEmailVerification
} from "firebase/auth";
import { auth } from "../../firebase.js";



const category_name = ["主菜", "副菜", "主食", "汁物", "デザート"]
const category_name_en = ["main", "side", "staple", "soup", "dessert"]
const category_color = ["#ffeddb", "#edffdb", "#ffdbed", "#dbedff", "#ffffdb"]

const genre_name = ["和風", "洋風", "中華風", "韓国風", "エスニック"]
const genre_color = ["#C66600", "#3366ff", "#ff7f7f", "#387d39", "#6f5436"]

// const api_url = "http://localhost:8000/"
const api_url = "https://api.menu-recommend.com/"

const theme = createTheme();

const createMonthRows = (year, month, dataDictionary) => {
  const rows = [];

  const firstDay = new Date(year, month - 1, 1);
  const lastDay = new Date(year, month, 0);

  let currentDate = new Date(firstDay);

  let tmp_date = new Date();

  while (currentDate <= lastDay) {
    // 週の最初の日曜日を取得
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());

    // 週の行
    const row = (
      <TableRow key={startOfWeek.toLocaleDateString()}>
        {[0, 1, 2, 3, 4, 5, 6].map((offset) => {
          const date = new Date(startOfWeek);
          date.setDate(startOfWeek.getDate() + offset);

          const formattedDate = date.toLocaleDateString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit' });
          const dayOfWeek = date.getDay(); // 0: 日曜日, 1: 月曜日, ..., 6: 土曜日

          const isSaturday = dayOfWeek === 6;
          const isSunday = dayOfWeek === 0;

          const cellStyle = {
            color: isSaturday ? 'blue' : (isSunday ? 'red' : 'black'),
          };

          return (
            <React.Fragment key={formattedDate}>
              <TableCell style={cellStyle}>
                {formattedDate} ({isSaturday ? '土' : isSunday ? '日' : ['月', '火', '水', '木', '金'][dayOfWeek-1]})
              </TableCell>
            </React.Fragment>
          );
        })}
      </TableRow>
    );

    rows.push(row);

    // データ1の行
    
    const data1Row = (
      <TableRow key={`${startOfWeek.toLocaleDateString()}-data1`}>
        {[0, 1, 2, 3, 4, 5, 6].map((i) => {
          // tmp_date を Date オブジェクトとして初期化
          let tmp_date = new Date(startOfWeek);

          // i 日進める
          tmp_date.setDate(startOfWeek.getDate() + i + 1); //TO DO
          let date_str = String(tmp_date.toISOString().split('T')[0])
          const tmp_year = tmp_date.getFullYear();
          const tmp_month = tmp_date.getMonth() + 1; // 月は0から始まるため+1する
          const tmp_day = tmp_date.getDate()-1;
          
          let recipe_title = ""
          if (dataDictionary.hasOwnProperty(date_str)) {
            dataDictionary[date_str].forEach((item, index) => {
              recipe_title += item.title;
              recipe_title += '\n';
              // if (index !== dataDictionary[date_str].length - 1) {
              //   recipe_title += '\n';
              // }
            });
          } else {
            recipe_title = "編集する";
          }
          return (
            <TableCell key={i}>
              <Button>
              <Link to={`/school/menu-table?year=${tmp_year}&month=${tmp_month}&day=${tmp_day}`} style={{ whiteSpace: 'pre-line' }}>
              {/* {String(startOfWeek)} */}

              {recipe_title}
              </Link>
              </Button>
            </TableCell>
          );
        })}
      </TableRow>
    );

    rows.push(data1Row);

    // // データ2の行
    // const data2Row = (
    //   <TableRow key={`${startOfWeek.toLocaleDateString()}-data2`}>
    //     {[0, 1, 2, 3, 4, 5, 6].map(() => (
    //       <TableCell>
    //         <Button>編集</Button>
    //         <Button>再推薦</Button>
    //       </TableCell>
    //     ))}
    //   </TableRow>
    // );

    // rows.push(data2Row);

    // 他のデータ列も追加

    // 1週間進める
    currentDate.setDate(currentDate.getDate() + 7);
  }

  return rows;
};


export const MenuList = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState("");
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, index) => currentYear - index);
  const months = Array.from({ length: 12 }, (_, index) => index + 1);
  const daysInMonth = (year, month) => new Date(year, month, 0).getDate();
  const [searchParams] = useSearchParams();

  const currentDate = new Date();
  const [selectedYear, setSelectedYear] = useState(searchParams.has('year') ? searchParams.get('year') : currentDate.getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(searchParams.has('month') ? searchParams.get('month') : currentDate.getMonth() + 1);
  const [dataDictionary, setDataDictionary] = useState({});
  const [days, setDays] = useState([]);


  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  // const toTableWithSetPosition = (year, month, day) => {
  //   navigate('/school/result-table#' + position, {state: {'body':location.state.body, 'month':location.state.month}});
  // }
  const handleYearChange = (year) => {
    setSelectedYear(year);
    setDays([]);
  };

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
    setDays(Array.from({ length: daysInMonth(selectedYear, month) }, (_, index) => index + 1));
  };

  const fetchData = async (user, year, month) => {
    try {
      const response = await fetch(`${api_url}school/menus?year=${year}&month=${month}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${user.accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      // console.log(result);

      setData(result);

      const dataDictionary_ = {};

      result.forEach(item => {
        const dateKey = item.date;
        if (!dataDictionary_[dateKey]) {
          dataDictionary_[dateKey] = [];
        }
        dataDictionary_[dateKey].push(item.recipe);
      });

      // ソートするための順序マップ
      const categoryOrder = {
        2: 0,
        0: 1,
        1: 2,
        3: 3,
        4: 4
      };

      // 日付ごとのデータをソート
      for (const key in dataDictionary_) {
        if (dataDictionary_.hasOwnProperty(key)) {
          dataDictionary_[key].sort((a, b) => categoryOrder[a.category] - categoryOrder[b.category]);
        }
      }

      // console.log(dataDictionary_);
      setDataDictionary(dataDictionary_);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      // console.log(currentUser)
      if(currentUser){
        fetchData(currentUser, selectedYear, selectedMonth);
    }
  });
  }, []);

  const [user, setUser] = useState(null);
    useEffect(() => {
      onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser);
        // console.log(currentUser)
        if(currentUser){
          fetchData(currentUser, selectedYear, selectedMonth);
      }
    });
    }, [selectedYear, selectedMonth]);

    const monthRows = createMonthRows(selectedYear, selectedMonth, dataDictionary);
    const pageStyle = {
      // 通常のページスタイル
      padding: '20px',
      border: '1px solid #ccc',
      // ログインしていない場合、操作を無効にするスタイル
      pointerEvents: user ? 'auto' : 'none',
      opacity: user ? 1 : 0.5, // ログインしていない場合の外観を変更（任意）
    };
  

  return (
    <>
    <Navigation/>  
    {/* <div>
      <DateSelect value={date} onChange={setDate} />
      <p>Selected date is: {date}</p>
    </div> */}
    {!user && (
      <Grid sx={{ flexGrow: 1, padding: 2 }} container justifyContent="center" alignItems="center" spacing={2} rowSpacing={2}> 
        <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{p: 3}}>
            <Grid item>
            {/* <Grid item> */}
                <Typography variant="body">
                    ユーザ登録をすると献立表の作成・管理ができます
                </Typography>
            </Grid>
            </Grid>
        </Grid>
      </Grid>
    )}
    <div style={pageStyle}>
    <Grid container justifyContent="center" alignItems="center" spacing={2} rowSpacing={2} sx={{pt: 3, pb: 3}}>
      <Grid item xs={12} sx={{ padding: 0.5 }}>
        <Grid container justifyContent="center" alignItems="center" spacing={1}>
          <Grid item>
            <Select
              labelId="year-label"
              id="year-selector"
              value={selectedYear}
              label="年"
              onChange={(e) => setSelectedYear(e.target.value)}
            >
              <MenuItem value="">選択してください</MenuItem>
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item><InputLabel id="year-label">年</InputLabel></Grid>            
          <Grid item>
            <Select
              labelId="month-label"
              id="month-selector"
              value={selectedMonth}
              label="月"
              onChange={(e) => setSelectedMonth(e.target.value)}
            >
              <MenuItem value="">選択してください</MenuItem>
              {months.map((month) => (
                <MenuItem key={month} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item><InputLabel id="month-label">月</InputLabel></Grid>
          </Grid>
      </Grid>
    </Grid>
      

        {/* <p>選択された生年月日: {`${selectedYear}年${selectedMonth}月`}</p> */}
      <Table>
      <TableHead>
        <TableRow>
          {/* <TableCell>日付</TableCell>
          <TableCell>データ列1</TableCell>
          <TableCell>データ列2</TableCell> */}
          {/* 他のデータ列も追加 */}
        </TableRow>
      </TableHead>
      <TableBody>
        {monthRows}
      </TableBody>
    </Table>
        <div>
          {/* データを表示するコンポーネントを追加 */}
          {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
        </div>

    </div>
    {/* <ResultTable navigate={navigate} location={location} Menu={Menu} month={month} start_day={start_day} /> */}
    <Footer/>
    </>
  );
  
}
export default MenuList;
