import React, { useState, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  getAuth,
  sendEmailVerification
} from "firebase/auth";
import { auth } from "../../firebase.js";
import { Navigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Navigation from '../contents/Navigation';

const theme = createTheme();

const UserInfo = () => {
    const user = auth.currentUser;

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: 30,
        width: 130,
        lineHeight: '30px',
        backgroundColor: "#F5DCDE",
    }));
    

    return (
        <>
        {user? (
        <>
        <ThemeProvider theme={theme}>
        <Navigation/>
        <Grid sx={{ flexGrow: 1, padding: 5 }} container justifyContent="center" alignItems="center" spacing={2} rowSpacing={2}> 
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{p: 3}}>
                <Grid item>
                {/* <Grid item> */}
                    <Typography variant="h4">
                        ユーザ情報
                    </Typography>
                </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{p: 1.5}}>
                    <Grid item>
                        <Item>表示名</Item>
                    </Grid>
                    <Grid item> 
                        <Typography variant="body1" sx={{ width: '30ch' }}>
                            {user.displayName}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{p: 1.5}}>
                    <Grid item>
                        <Item>メールアドレス</Item>
                    </Grid>
                    <Grid item> 
                        <Typography variant="body1" sx={{ width: '30ch' }}>
                            {user.email}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </ThemeProvider>
        </>
        ) : (
            <Navigate to={`/school`} />
        )}
        </>
    );
};

export default UserInfo;