import React from "react";
import { Link } from "react-router-dom";
import Navigation from '../contents/Navigation'
import MenuForm from '../contents/MenuForm'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import jaLocale from '@fullcalendar/core/locales/ja';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import {default as styled_emotion} from '@emotion/styled'
import PropTypes from 'prop-types';
import { animateScroll as scroll } from "react-scroll";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {useLocation, useNavigate} from "react-router-dom";

import {
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    getAuth,
    sendEmailVerification
  } from "firebase/auth";
import { auth } from "../../firebase.js";

//
// データ整形
//

// import Recipes from '../recipes_0725_edit_costari_seasonari.json';
// import Menu from '../menu_0814.json';
import { click } from "@testing-library/user-event/dist/click";
import Footer from "../contents/Footer";
import { SystemSecurityUpdateGoodOutlined } from "@mui/icons-material";
// console.log(Recipes);
// console.log(Menu);

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`calendar-panel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `calendar-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


export const StyleWrapper = styled_emotion.div`
.fc-day-other {
    background-color: lightgray;
}

.fc-day-sat {
    background-color: #eaf4ff;
}
.fc-day-sun {
    background-color: #ffeaea;
}
.fc-daygrid-event {
    &.event_holiday {
        background-color: #ffeaea;
    //   display: none;
    }
  }
// .fc {
//     width: 80%;
//     // display: flex;
//     // align-items: center;
//     // justify-content: center;
//     // text-align: center;
// }  
`;

const category_name = ["主菜", "副菜", "主食", "汁物", "デザート"]
const category_name_en = ["main", "side", "staple", "soup", "dessert"]
const category_color = ["#ffeddb", "#edffdb", "#ffdbed", "#dbedff", "#e9ddee"]

const genre_name = ["和風", "洋風", "中華風", "韓国風", "エスニック"]
const genre_color = ["#C66600", "#3366ff", "#ff7f7f", "#387d39", "#6f5436"]

const ToggleContent = ({ children, title }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleContent = () => {
      setIsOpen(!isOpen);
    };
  
    return (
      <div>
        <div onClick={toggleContent} style={{ cursor: 'pointer' }}>
          {isOpen ? '▼' : '▶'} {title}
        </div>
        {isOpen && <div>{children}</div>}
      </div>
    );
  };

const theme = createTheme();

function ResultCalendar({navigate, location, Menu, year, month, start_day, tabIndex, user, input}) {
    const menu_events = [];
    let nutritions = {}
    nutritions["energy"] = 0;
    nutritions["protein"] = 0;
    nutritions["lipid"] = 0;
    nutritions["sodium"] = 0;
    
    let sum_cost = 0
    let constraints = []

    const menuWithDetail = [];
    // console.log(Menu)
    Menu.forEach(function(week_result, i) {
        // console.log(week_result['menus']);
        // console.log(week_result['H_energy'])
        constraints.push(week_result['H_energy'])
        week_result['menus'].forEach(function(day_result, j) {
            let data = {};
            data["date"] = year+'-'+('0'+month).slice(-2)+'-'+('0'+(start_day+i*7+j)).slice(-2);
            // console.log(data["date"]);
            data["overview"] = {}
            // 牛乳分を足しておく
            data["overview"]["energy"] = day_result['info']['energy'];
            data["overview"]["protein"] = day_result['info']['protein'];
            data["overview"]["lipid"] = day_result['info']['fat'];
            data["overview"]["sodium"] = day_result['info']['sodium'];
            data["overview"]["cost"] = day_result['info']['cost'];
            
            // 主食 主菜 副菜 汁物 デザート の順にしたい
            // indexは、2, 0, 1, 3, 4 
            let category_array = [];
            for(let i=0;i<5;i++){
                category_array.push([]);
            }
            day_result['menu'].forEach(function(recipe, k) {
                category_array[recipe['category']].push(recipe);
            });
            data["menu"] = [
                ...category_array[2],
                ...category_array[0],
                ...category_array[1],
                ...category_array[3],
                ...category_array[4]
            ]
            nutritions["energy"] += data["overview"]["energy"];
            nutritions["protein"] += data["overview"]["protein"];
            nutritions["lipid"] += data["overview"]["lipid"];
            nutritions["sodium"] += data["overview"]["sodium"];
            sum_cost += data["overview"]["cost"];
            menuWithDetail.push(data);
        });
    });
    // console.log(menuWithDetail);

    // カレンダー用に整形
    menuWithDetail.forEach((row, i) => {
        for(let j=0;j<row["menu"].length;j++){
        menu_events.push(
            {
                title: row["menu"][j]["title"],
                start: row["date"], 
                borderColor: "gray", 
                backgroundColor: category_color[row["menu"][j]["category"]],
                textColor: "black"
            }
        );
        }
        //最後に牛乳
        menu_events.push(
            {
                title:'牛乳', 
                start: row["date"],   
                borderColor: "gray", 
                backgroundColor: "#ffffff", 
                textColor: "black"
            }
        );
    });
    // console.log(menu_events)

    //
    // データ整形終わり
    //
    
    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: 30,
        width: 100,
        lineHeight: '30px',
        backgroundColor: "#F5DCDE",
    }));

    const ItemNutrition = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: 30,
        width: 100,
        lineHeight: '30px',
        backgroundColor: "#ffd4b1",
    }));


    const toTable = () => {
        navigate('/school/result-table', {state: {'body':location.state.body, 'year':location.state.year, 'month':location.state.month, 'tabIndex': tabIndex, 'input': location.state.input}});
    }

    // 栄養素用
    const [nutritionCategory, setNutritionCategory] = useState('0');
    const scrollToTop = () => {
        scroll.scrollToTop();
    };

    const scrollToElement = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

    const ConstraintsComponent = ({ constraints }) => {
        // console.log(constraints);

        let strong_penalty = ["staple", "main", "side", "soup", "dessert", "energy", "protein", "fat", "sodium", "cost"]
        let le1 = ["energy", "protein", "fat", "sodium", "cost"] //1以下ならよし
        let weak_penalty = ["duplication", "genre", "combination", "variety", "evaluation"]

        let constraint_name = {
            "staple": "主食", "main": "主菜", "side": "副菜", "soup": "汁物", "dessert": "デザート", 
            "energy": "エネルギー", "protein": "たんぱく質", "fat": "脂質", "sodium": "ナトリウム", "cost": "費用"
        }

        // const [feasibleFlag, setFeasibleFlag] = useState(true);
        let feasible_flag = true;
    
        return (
            <Grid container spacing={1} sx={{p: 3}}>
                <Grid container justifyContent="center" alignItems="center">
                <Typography align="center">満たしていない制約</Typography>
                </Grid>
                {constraints.map((constraint, index) => {
                    feasible_flag = true;
                    // valueが0でない場合のみChipを表示
                    return (
                    <Grid item xs={12} key={index} sx={{ m: 0.5 }}>
                        <Grid container justifyContent="center" alignItems="center">
                            <Item>{index+1}週目</Item>
                            <Box sx={{ display: 'flex', ml: 3 }}>
                                {Object.keys(constraint).map(key => {
                                    // 条件に合致する場合にのみChipを表示
                                    if (strong_penalty.includes(key)){
                                        if(le1.includes(key)){
                                            if(constraint[key] > 1.5){
                                                // console.log(key, constraint[key])
                                                feasible_flag = false
                                                return (
                                                    <Chip
                                                        key={key}
                                                        label={constraint_name[key]}
                                                        color="default"
                                                        sx={{
                                                            fontColor: "#ffffff",
                                                            marginRight: 1
                                                            // 他のスタイルを設定することもできます
                                                        }}
                                                    />
                                                );
                                            }
                                        }
                                        else{
                                            if(constraint[key] !== 0){
                                                // console.log(key, constraint[key])
                                                feasible_flag = false
                                                return (
                                                    <Chip
                                                        key={key}
                                                        label={constraint_name[key]}
                                                        color="default"
                                                        sx={{
                                                            fontColor: "#ffffff",
                                                            marginRight: 1
                                                            // 他のスタイルを設定することもできます
                                                        }}
                                                    />
                                                );
                                            }
                                        }   
                                    }
                                })}
                                {feasible_flag && (
                                    <Typography>全て満たしています！</Typography>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
      )})}
      
            </Grid>
        );
        
    };
        
    return (
    <>
    <ArrowUpwardIcon sx={{
        backgroundColor: "rgba(0,0,0,0.8)",
        color: "white",
        position: "fixed",
        bottom: "40px",
        right: "40px",
        width: 25,
        height: 25,
        p: 1.5,
        zIndex: 2
    }}
    onClick={scrollToTop}
    />
    <ThemeProvider theme={theme}>
        <Grid sx={{ flexGrow: 1, padding: 1 }} container rowSpacing={1}> 
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                    {/* <Button href="./result-table" variant="outlined">テーブル表示</Button> */}
                    <Button onClick={toTable} variant="outlined">テーブル表示</Button>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={10}>
                        <StyleWrapper>
                            <FullCalendar 
                            plugins={[dayGridPlugin]} 
                            initialView="dayGridMonth"
                            // initialView={user ? "dayGridMonth" : "dayGridWeek"}
                            initialDate={year+'-'+('0'+month).slice(-2)+'-01'}
                            locales={[jaLocale]} 
                            locale='ja'   
                            height={"auto"}
                            events={menu_events}
                            eventClick={(clickInfo) => {
                                const targetId = clickInfo.event.startStr + '-' + tabIndex;
                                // scrollToElement(targetId);
                                navigate('/school/result-table#'+targetId, { state: { 'body': location.state.body, 'year': location.state.year, 'month': location.state.month, 'tabIndex': tabIndex, 'input': location.state.input} });
                            }}
                            eventOrder={true}
                            />
                        </StyleWrapper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
        <Grid item>
            <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{pt: 3}}>
                <Grid item>
                    <Item>費用の合計</Item>
                </Grid>
                <Grid item>
                    <Typography sx={{ width: '15ch'}}>
                        {sum_cost.toFixed(1)} 円
                    </Typography>
                </Grid>
            </Grid>
        </Grid>    
    </Grid>
            <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{pt: 10}}>
      <Grid item>
            <Paper variant="outlined" sx={{p: 3}}>
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
                <Grid item>
                    <Typography>
                        理想値の設定（1日分）
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ padding: 1 }}>
                    <Grid item>   
                        <Grid container sx={{ paddingBottom: 0.5 }} justifyContent="center" alignItems="center" spacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item>
                                <Grid container justifyContent="center" alignItems="center" spacing={3} >
                                    <Grid item xs={6}>
                                        <ItemNutrition>エネルギー</ItemNutrition>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={{ width: '15ch'}}>
                                            {
                                                input["nutritions"]["energy"] ? (
                                                <Typography sx={{ width: '15ch' }}>
                                                {input["nutritions"]["energy"].toFixed(1)} kcal
                                                </Typography>
                                            ) : null
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container justifyContent="center" alignItems="center" spacing={3} >
                                    <Grid item xs={6}>
                                        <ItemNutrition>脂質</ItemNutrition>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={{ width: '15ch'}}>
                                        {
                                            input["nutritions"]["fat"] ? (
                                                <Typography sx={{ width: '15ch' }}>
                                                {input["nutritions"]["fat"].toFixed(1)} g
                                                </Typography>
                                            ) : null
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>   
                        <Grid container justifyContent="center" alignItems="center" spacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item>
                                <Grid container justifyContent="center" alignItems="center" spacing={3} >
                                    <Grid item xs={6}>
                                        <ItemNutrition>たんぱく質</ItemNutrition>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={{ width: '15ch'}}>
                                            {
                                            input["nutritions"]["protein"] ? (
                                                <Typography sx={{ width: '15ch' }}>
                                                {input["nutritions"]["protein"].toFixed(1)} g
                                                </Typography>
                                            ) : null
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container justifyContent="center" alignItems="center" spacing={3} >
                                    <Grid item xs={6}>
                                        <ItemNutrition>ナトリウム</ItemNutrition>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Typography sx={{ width: '15ch'}}>
                                        {
                                        input["nutritions"]["sodium"] ? (
                                            <Typography sx={{ width: '15ch' }}>
                                            {input["nutritions"]["sodium"].toFixed(1)} mg
                                            </Typography>
                                        ) : null
                                        }
                                    </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>   
                        <Grid container justifyContent="center" alignItems="center" spacing={{ xs: 1, sm: 2, md: 3 }} sx={{pt: 2}}>
                            <Grid item>
                                <Grid container justifyContent="center" alignItems="center" spacing={3} >
                                    <Grid item xs={6}>
                                        <Item>費用</Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={{ width: '15ch'}}>
                                          {input["cost_day"].toFixed(1)} 円
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
               
            </Paper>
            </Grid>
            </Grid>
            
                        
            <ConstraintsComponent constraints={constraints}/>

            <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{pt:3, pb: 6, pr: 3, pl: 3}}>
            <ToggleContent title="制約とは？">
                <Grid container direction="column" alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{p: 2}}>
                        このアプリケーションでは、献立をさまざまな条件に基づいて作成しています。<br />
                        特に、以下の4つの条件が満たされているかを確認して、上の「満たされていない制約」に表示しています。
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper sx={{p: 3}}>
                        <Typography variant="body1">
                            ① 献立の構成 (主食と主菜が1品、副菜、汁物、デザートが0-1品)<br />
                            ② 献立の栄養素の合計値を理想値に近づける<br />
                            ③ 費用を理想値に近づける<br />
                            ④ 同じレシピが月に2回以上出ないようにする<br />
                        </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{p: 2}}>
                        この条件以外に、以下の条件をなるべく満たすことができるように最適化をしています。
                        </Typography>
                        <Paper sx={{p: 3}}>
                        <Typography variant="body1">
                        ⑤ 1日分の献立の中での料理のジャンルをそろえる<br />
                        ⑥ 主食に合うレシピを推薦する<br />
                        ⑦ 似ている献立(使っている食材が同じ、料理のジャンルが同じ)が隣りあわないようにする<br />
                        ⑧ ユーザからの評価(悪いと判断されたレシピの組み合わせは出さないようにする)
                        </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{p: 2}}>
                        現在表示されている献立では満たされていなくても、何回か推薦を試すことで満たされることもあります。
                        </Typography>
                    </Grid>
                </Grid>
            </ToggleContent>
            </Grid>
        </Grid>
    </ThemeProvider>
    <Footer/>                 
    </>
);

}

export const ResultCalendars = () => {
    const navigate = useNavigate();
    const location = useLocation(); 
    const [tabValue, setTabValue] = useState(0);
    const [user, setUser] = useState(null);

    useEffect(() => {
        if(location.state.tabIndex){
            setTabValue(location.state.tabIndex);
        }
        onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            // console.log(currentUser)
        });

    },[])
    
    
    let Menus = location.state?.body || [];
    let month = location.state?.month || 0;
    let year = location.state?.year || 0;
    let input_data = location.state?.input || {
        nutritions: {
            energy: null,
            fat: null,
            protein: null,
            sodium: null
        },
        cost_day: null
    };
    // console.log(month);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
      };

    const getMonday = (requestMonth) => {
        // console.log(requestMonth)
        for(let i=1;i<10;i++){
            const target = new Date(year,requestMonth-1,i);
            // console.log(target)
            // console.log(target.getDay())
            if(target.getDay() === 1){
                return i
            }
        }
    }
    const start_day = getMonday(month);
    return (
        <>
         <Navigation/>  
        <Box sx={{ width: '100%', pt:3}}>
        <ResultCalendar navigate={navigate} location={location} Menu={Menus} year={year} month={month} start_day={start_day} tabIndex={0} user={user} input={input_data}/>

    </Box>
        {/* <ResultCalendar navigate={navigate} location={location} Menu={Menu} month={month} start_day={start_day}/> */}
        </>
    );
    
}
