import React from "react";
import { Link } from "react-router-dom";
import Navigation from '../contents/Navigation'
import MenuForm from '../contents/MenuForm'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import jaLocale from '@fullcalendar/core/locales/ja';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import {default as styled_emotion} from '@emotion/styled'
import Footer from "../contents/Footer";

export const StyleWrapper = styled_emotion.div`
.fc-day-other {
    background-color: lightgray;
}

.fc-day-sat {
    background-color: #eaf4ff;
}
.fc-day-sun {
    background-color: #ffeaea;
}
.fc-daygrid-event {
    &.event_holiday {
        background-color: #ffeaea;
    //   display: none;
    }
  }
// .fc {
//     width: 80%;
//     // display: flex;
//     // align-items: center;
//     // justify-content: center;
//     // text-align: center;
// }  
`;

const july_date = [3, 4, 5, 6, 7, 10, 11, 12, 13, 14, 18, 19, 20, 21, 24, 25, 26, 27, 28, 31]

const events_july = [];

july_date.forEach((d) => {
    events_july.push(
        {
            title:'ごはん', 
            start: '2023-07-' + ('0'+d).slice(-2), 
            borderColor: "gray", 
            backgroundColor: "#cce2ff", 
            textColor: "black"
        }
    );
    events_july.push(
        {
            title:'おかず', 
            start: '2023-07-' + ('0'+d).slice(-2), 
            borderColor: "gray", 
            backgroundColor: "#b8e6de", 
            textColor: "black"
        }
    );
    events_july.push(
        {
            title:'おかず', 
            start: '2023-07-' + ('0'+d).slice(-2),  
            borderColor: "gray", 
            backgroundColor: "#b8e6de", 
            textColor: "black"
        }
    );
    events_july.push(
        {
            title:'しるもの', 
            start: '2023-07-' + ('0'+d).slice(-2),  
            borderColor: "gray", 
            backgroundColor: "#e6cfe2", 
            textColor: "black"
        }
    );
    events_july.push(
        {
            title:'牛乳', 
            start: '2023-07-' + ('0'+d).slice(-2),  
            borderColor: "gray", 
            backgroundColor: "#ffffff", 
            textColor: "black"
        }
    );
});

const theme = createTheme();

export const PastMenu = () => {
    
    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: 30,
        width: 100,
        lineHeight: '30px',
        backgroundColor: "#F5DCDE",
      }));

    const ItemNutrition = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: 30,
        width: 100,
        lineHeight: '30px',
        backgroundColor: "#ffd4b1",
      }));



    // 栄養素用
    const [nutritionCategory, setNutritionCategory] = useState('0');

  return (
    <>
    <ThemeProvider theme={theme}>
        <Navigation/>  

        <Grid sx={{ flexGrow: 1, padding: 3 }} container rowSpacing={1}> 
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={10}>
                        <StyleWrapper>
                            <FullCalendar 
                            plugins={[dayGridPlugin]} 
                            initialView="dayGridMonth"
                            locales={[jaLocale]} 
                            locale='ja'   
                            height={"auto"}
                            events={events_july}
                            eventOrder={true}
                            />
                        </StyleWrapper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" spacing={3} >
                    <Grid item>
                        合計
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ padding: 1 }}>
                    <Grid item>   
                        <Grid container sx={{ paddingBottom: 0.5 }} justifyContent="center" alignItems="center" spacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item>
                                <Grid container justifyContent="center" alignItems="center" spacing={3} >
                                    <Grid item>
                                        <ItemNutrition>エネルギー</ItemNutrition>
                                    </Grid>
                                    <Grid item>
                                        <TextField id="nutrition_energy"
                                            // value={nutritions.find(el => el.value === nutritionCategory)['energy']}
                                            size="small"
                                            sx={{ width: '10ch'}}
                                            InputProps={{
                                                inputMode: 'numeric', pattern: '[0-9]*',
                                                endAdornment: <InputAdornment position="end">kcal</InputAdornment>,
                                                style: { height: "30px"},
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container justifyContent="center" alignItems="center" spacing={3} >
                                    <Grid item>
                                        <ItemNutrition>脂質</ItemNutrition>
                                    </Grid>
                                    <Grid item>
                                        <TextField id="hiyou"
                                            // value={nutritions.find(el => el.value === nutritionCategory)['lipid']}
                                            size="small"
                                            sx={{ width: '10ch'}}
                                            InputProps={{
                                                inputMode: 'numeric', pattern: '[0-9]*',
                                                // endAdornment: <InputAdornment position="end">g</InputAdornment>,
                                                style: { height: "30px"},
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>   
                        <Grid container justifyContent="center" alignItems="center" spacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item>
                                <Grid container justifyContent="center" alignItems="center" spacing={3} >
                                    <Grid item>
                                        <ItemNutrition>たんぱく質</ItemNutrition>
                                    </Grid>
                                    <Grid item>
                                        <TextField id="hiyou"
                                            // value={nutritions.find(el => el.value === nutritionCategory)['protein']}
                                            size="small"
                                            sx={{ width: '10ch'}}
                                            InputProps={{
                                                inputMode: 'numeric', pattern: '[0-9]*',
                                                endAdornment: <InputAdornment position="end">g</InputAdornment>,
                                                style: { height: "30px"},
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container justifyContent="center" alignItems="center" spacing={3} >
                                    <Grid item>
                                        <ItemNutrition>ナトリウム</ItemNutrition>
                                    </Grid>
                                    <Grid item>
                                        <TextField id="hiyou"
                                            // value={nutritions.find(el => el.value === nutritionCategory)['sodium']}
                                            size="small"
                                            sx={{ width: '10ch'}}
                                            InputProps={{
                                                inputMode: 'numeric', pattern: '[0-9]*',
                                                endAdornment: <InputAdornment position="end">mg未満</InputAdornment>,
                                                style: { height: "30px"},
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" spacing={3} >
                        <Grid item>
                            <Item>費用(1ヶ月)</Item>
                        </Grid>
                        <Grid item>
                            <TextField id="hiyou"
                                size="small"
                                InputProps={{
                                    inputMode: 'numeric', pattern: '[0-9]*',
                                    endAdornment: <InputAdornment position="end">円</InputAdornment>,
                                }}
                                sx={{ width: '20ch' }}
                                />
                        </Grid>
                    </Grid>
                </Grid>    
            </Grid>
        </Grid>
    </ThemeProvider>
    <Footer/>
    </>
);
}

