/* Login.js */

import React, { useState, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  getAuth,
  sendPasswordResetEmail
} from "firebase/auth";
import { auth } from "../../firebase.js";
import { Navigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import SendIcon from '@mui/icons-material/Send';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Navigation from '../contents/Navigation';

const theme = createTheme();

const ChangePassword = () => {
    /* ↓state変数を定義 */
    const [loginEmail, setLoginEmail] = useState("");
    const [verifyMail, setVerifyMail] = useState(true);

    const [snackbarState, setSnackbarState] = useState({
        open: false,
        status: '',
        message: '',
    });

    const [user, setUser] = useState(null);
    // const auth = getAuth();
    // const user = auth.currentUser;

    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            console.log(currentUser);
        });
    }, []);

    const handleClose = () => {
        setSnackbarState({
            open: false,
            status: '',
            message: '',
        });
    };

    const passwordReset = async (e) => {
        e.preventDefault();
        const actionCodeSettings = {
            // パスワード再設定後のリダイレクト URL
            url: 'https://menu-recommend.com/school/login?email=' + loginEmail
        }
        try {
            await sendPasswordResetEmail(auth, loginEmail, actionCodeSettings)
            .then(() => {
                setSnackbarState({
                    open: true,
                    status: 'success',
                    message: 'パスワード再設定メールを送信しました',
                });
            });
            // return
        } catch (error) {
            console.log(error.code)
            switch (error.code) {
            case "auth/user-not-found":
                setSnackbarState({
                    open: true,
                    status: 'error',
                    message: 'ユーザが見つかりません',
                });
                break
            case "auth/invalid-login-credentials":
                setSnackbarState({
                    open: true,
                    status: 'error',
                    message: 'メールアドレスまたはパスワードが違います',
                });
                break
            case "auth/invalid-email":
                setSnackbarState({
                    open: true,
                    status: 'error',
                    message: '無効なメールアドレスです',
                });
                break
            case "auth/missing-password":
                setSnackbarState({
                    open: true,
                    status: 'error',
                    message: 'パスワードを入力してください',
                });
                break
            case "auth/wrong-password":
                setSnackbarState({
                    open: true,
                    status: 'error',
                    message: 'メールアドレスまたはパスワードが違います',
                });
                break
            default:
                setSnackbarState({
                    open: true,
                    status: 'error',
                    message: 'エラーが起きました' + error.code,
                });
                break
                // その他のエラー時の処理
            }
            // return
        }
        };
        const Item = styled(Paper)(({ theme }) => ({
            ...theme.typography.body2,
            textAlign: 'center',
            color: theme.palette.text.secondary,
            height: 30,
            width: 130,
            lineHeight: '30px',
            backgroundColor: "#F5DCDE",
        }));
    

    return (
        <>
        {/* ↓ログインしている場合、マイページにリダイレクトする設定 */}
        {user? (
            // <>あ</>
            <Navigate to={`/school`} />
        ) : (
        <>
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarState['open']}
            autoHideDuration={6000}
            onClose={handleClose}
        >
        <Alert severity={snackbarState['status']}>{snackbarState['message']}</Alert>
        </Snackbar>
        <ThemeProvider theme={theme}>
        <Navigation/>
        <Grid sx={{ flexGrow: 1, padding: 5 }} container justifyContent="center" alignItems="center" spacing={2} rowSpacing={2}> 
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{p: 3}}>
                <Grid item>
                {/* <Grid item> */}
                    <Typography variant="h4">
                        パスワード再設定
                    </Typography>
                </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{p: 1.5}}>
                    <Grid item>
                        <Item>メールアドレス</Item>
                    </Grid>
                    <Grid item> 
                        <OutlinedInput
                            name="email"
                            type="email"
                            value={loginEmail}
                            onChange={(e) => setLoginEmail(e.target.value)}
                            // label="Select"
                            // defaultValue={nutritions[2]['value']}
                            size="small"
                            // helperText="Please select your currency"
                            sx={{ width: '30ch' }}
                            required
                        />
                    </Grid>
                </Grid>
            </Grid>  
            <Grid item xs={12}>
                <Grid container sx={{pt: 1}} justifyContent="center" alignItems="center">
                    <Grid item>
                        <Grid container direction="column" alignItems="center" spacing={2}>
                            <Grid item>
                            <Button variant="outlined" endIcon={<SendIcon />}  onClick={passwordReset}>送信</Button>
                            </Grid>
                            <Grid item>
                            <Typography>メールが届かない場合は、ユーザ登録をしてください。</Typography>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </ThemeProvider>
        </>
        )}
        </>
    );
};

export default ChangePassword;