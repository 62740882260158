import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navigation from '../contents/Navigation';
// import MenuForm from '../contents/MenuForm';
import Footer from "../contents/Footer";
import {
    signOut,
    onAuthStateChanged
} from "firebase/auth";
import { auth } from "../../firebase.js";
import { useNavigate } from "react-router-dom";
import MyForm from '../contents/InputSet';


export const TopPage = () => {
  return (
    <>
    <Navigation/>
    <MyForm/>
    <Footer/>
    </>
  );
}