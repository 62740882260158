import * as React from 'react';
import { Container, Typography, Grid, Link } from '@mui/material';

const Footer = () => {
    return (
        <Container component="footer" sx={{ mt: 'auto', pt: 6, pb: 6}}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Typography variant="body2" sx={{pr: 3, pl: 3}}>
              <Link href="/school/terms" color="#666666" underline="hover">
                利用規約
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={{pr: 3, pl: 3}}>
              <Link href="/school/privacy" color="#666666" underline="hover">
                プライバシーポリシー
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={{pr: 3, pl: 3}}>
              <Link href="https://forms.gle/TqTAoLp4mmZs7Dkx9" target="_blank" color="#666666" underline="hover">
                お問い合わせ
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
        <Grid item>
        
        <Typography variant="body2" sx={{pt: 3}} color="#666666">
        本サービスは 
        <Link href='https://www.ipa.go.jp/jinzai/mitou/target/2023/gaiyou_tr-1.html'>
         2023年度未踏ターゲット事業
        </Link> 
        の支援を受けて開発・運営されています。
        </Typography>
        </Grid>
        </Grid>
      </Container>
    );
  };
  

export default Footer;