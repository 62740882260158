// import fakeRestDataProvider from "ra-data-fakerest";
// import data from "./data.json";

// export const dataProvider = fakeRestDataProvider(data, true);
import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

import React, { useState, useEffect } from "react";
import jsonServerProvider from "ra-data-json-server";

import fakeDataProvider from 'ra-data-fakerest';
// import Data from '../recipes_0725_edit_costari_seasonari.json';


// const api_url = "http://localhost:8000/"
// const api_url = "https://api.menu-recommend/"
// const apiUrl = 'http://localhost:8000/school/';
const apiUrl = "https://api.menu-recommend.com/school/"
// const httpClient = fetchUtils.fetchJson;

const httpClient = (url, user, options = {}) => {
    if (!options.headers) {
        options.headers = { Accept: 'application/json' };
        // console.log(options);
    }
    // add your own headers here
    if (user) {
        options.headers.Authorization = `Bearer ${user.accessToken}`;
        // console.log(options);
    }
    // console.log(options.headers);
    return fetchUtils.fetchJson(url, options);
};
// データ整形用の関数

const formatDetail = (elem) => {
    elem["is_month_list"] = elem["is_month"].reduce((result, value, index) => {
        if (value === 1) {
            result.push(index + 1);
        }
        return result;
    }, []);

    const filter_category = {
        "staple": false,
        "main": false,
        "side": false,
        "soup": false,
        "dessert": false
    }
    
    if(elem["category"] === 2){
        filter_category["staple"] = true
    }
    else if(elem["category"] === 0){
        filter_category["main"] = true
    }
    else if(elem["category"] === 1){
        filter_category["side"] = true
    }
    else if(elem["category"] === 3){
        filter_category["soup"] = true
    }
    else if(elem["category"] === 4){
        filter_category["dessert"] = true
    }
    elem["filter_category"] = filter_category

    const filter_genre = {
        "japanese": false,
        "western": false,
        "chinese": false,
        "korean": false,
        "esnic": false
    }

    if(elem["genre"] === 0){
        filter_genre["japanese"] = true
    }
    else if(elem["genre"] === 1){
        filter_genre["western"] = true
    }
    else if(elem["genre"] === 2){
        filter_genre["chinese"] = true
    }
    else if(elem["genre"] === 3){
        filter_genre["korean"] = true
    }
    else if(elem["genre"] === 4){
        filter_genre["esnic"] = true
    }

    elem["filter_genre"] = filter_genre

    return elem;
}

const formatData = (data, resource) => {
    const formattedData = [];
    
    const staple = new Array(); 
    const main = new Array(); 
    const side = new Array(); 
    const soup = new Array(); 
    const dessert = new Array(); 
    // ここでデータを整形
    data.forEach(function (elem) {
        if(elem["category"] === 2){
            staple.push(elem);
        }
        else if(elem["category"] === 0){
            main.push(elem);
        }
        else if(elem["category"] === 1){
            side.push(elem);
        }
        else if(elem["category"] === 3){
            soup.push(elem);
        }
        else if(elem["category"] === 4){
            dessert.push(elem);
        }

        formattedData.push(formatDetail(elem));
    });

    data = {
        all: formattedData,
        staple: staple,
        main: main,
        side: side,
        soup: soup,
        dessert: dessert,
    }

    return data[resource]
};

const formatDataYokosuka = (data, resource) => {
    const formattedData = [];
    
    const staple = new Array(); 
    const main = new Array(); 
    const side = new Array(); 
    const soup = new Array(); 
    const dessert = new Array(); 
    // ここでデータを整形
    data.forEach(function (elem) {
        if(elem["category"] === 2){
            staple.push(elem);
        }
        else if(elem["category"] === 0){
            main.push(elem);
        }
        else if(elem["category"] === 1){
            side.push(elem);
        }
        else if(elem["category"] === 3){
            soup.push(elem);
        }
        else if(elem["category"] === 4){
            dessert.push(elem);
        }

        formattedData.push(formatDetail(elem));
    });

    data = {
        all_yokosuka: formattedData,
        staple_yokosuka: staple,
        main_yokosuka: main,
        side_yokosuka: side,
        soup_yokosuka: soup,
        dessert_yokosuka: dessert,
    }

    return data[resource]
};
const fetchData = async (url, user, method, body=undefined) => {
    try {
        let headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' };
        if(user){
            headers['Authorization'] = `Bearer ${user.accessToken}`
        }
        // console.log(headers)
        const response = await fetch(url, {
            method: method,
            headers: headers,
            body: body !== undefined ? JSON.stringify(body) : undefined
        });
        // console.log(response)
        // console.log(JSON.stringify(body))

        const result = await response.json();
        return result            
        
    } catch (error) {
    console.error("Error fetching data:", error);
    }
};

export const dataProvider = (user) =>  ({
    getList: async (resource, params) => {
        
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
        };
        // const url = `${apiUrl}/${resource}?${stringify(query)}`;
        let url = ""
        // let headers = {}
        let is_yokosuka = false
        if(resource.includes("_yokosuka")){
            url = `${apiUrl}yokosuka_recipes`;
            is_yokosuka = true;
        } else{
            url = `${apiUrl}recipes`;
        }

        // console.log(params.filter)

        let json = await fetchData(url, user, 'GET')
        // console.log(json)
        

        if (json.length){
            let formattedData = []
            if(is_yokosuka){
                formattedData = formatDataYokosuka(json, resource);
            }
            else{
                formattedData = formatData(json, resource);
            }
            // console.log(formattedData)
            // const formattedData = await formatData(json, resource);
            const total = formattedData.length //parseInt(headers.get('content-range').split('/').pop(), 10);

            // クライアント側で名前にフィルタリング
            let filteredData = formattedData
            if (Object.keys(params.filter).length){
                filteredData = formattedData.filter(item => {
                    if (params.filter) {
                        // 名前にフィルタの文字列が含まれ、かつジャンルが一致する場合に表示
                        const titleMatch = params.filter.q=== undefined || item.title.toLowerCase().includes(params.filter.q.toLowerCase());
                        const genreMatch = params.filter.filter_genre === undefined || item.filter_genre[Object.keys(params.filter.filter_genre)];
                        // console.log(item.filter_genre[Object.keys(params.filter.filter_genre)], Object.keys(params.filter.filter_genre))
                        return titleMatch && genreMatch;
                    }
                    return true; // フィルタ条件がない場合は全てのデータを表示
                });
            }
            
            // クライアント側でページング
            const start = (page - 1) * perPage;
            const end = page * perPage;
            const slicedData = filteredData.slice(start, end);
            // console.log({
            //     data: slicedData,
            //     total: total,
            // })
            return {
                data: slicedData,
                total: total,
            };

        }
        else{
            return {
                data: [],
                total: 0,
            };
        }
    },

    getOne: async (resource, params) => {
        let url = ""
        if(resource.includes("_yokosuka")){
            url = `${apiUrl}yokosuka_recipes/${params.id}/`;
        } else{
            url = `${apiUrl}recipes/${params.id}/`;
        }
        let json = await fetchData(url, user, 'GET')

        return {
            data: formatDetail(json),
        }
    },

    // getMany: (resource, params) => {
    //     const query = {
    //         filter: JSON.stringify({ ids: params.ids }),
    //     };
    //     const url = `${apiUrl}/${resource}?${stringify(query)}`;
    //     return httpClient(url).then(({ json }) => ({ data: json }));
    // },

    // getManyReference: (resource, params) => {
    //     const { page, perPage } = params.pagination;
    //     const { field, order } = params.sort;
    //     const query = {
    //         sort: JSON.stringify([field, order]),
    //         range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
    //         filter: JSON.stringify({
    //             ...params.filter,
    //             [params.target]: params.id,
    //         }),
    //     };
    //     const url = `${apiUrl}/${resource}?${stringify(query)}`;

    //     return httpClient(url).then(({ headers, json }) => ({
    //         data: json,
    //         total: parseInt(headers.get('content-range').split('/').pop(), 10),
    //     }));
    // },

    update: async (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        console.log("update");
        let url = `${apiUrl}recipes/${params.id}/`

        // console.log(params.data)

        const is_month = Array(12).fill(0);

        // is_month_listの月を1に設定
        params.data.is_month_list.forEach(month => {
            is_month[month - 1] = 1; // 月は1から始まるため、indexはmonth-1となる
        });
        let body = {
            // id: params.data.id,
            title: params.data.title,
            category: params.data.category,
            genre: params.data.genre,
            is_month: is_month,
            ingredients: params.data.ingredients,
            nutritions: params.data.nutritions,
            date: params.data.date,
        }
        // console.log(body)

    //     class Recipe(BaseModel):
    // # uid: str 
    // title: str 
    // category: int
    // genre: int
    // is_month: list
    // ingredients: list
    // nutritions: dict
    // date: list
        let json = await fetchData(url, user, 'PUT', body)
        return { data: json }

        // return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
        //     method: 'PUT',
        //     body: JSON.stringify(params.data),
        // }).then(({ json }) => ({ data: json }));
    },

        // httpClient(`${apiUrl}/${resource}/${params.id}`, {
        //     method: 'PUT',
        //     body: JSON.stringify(params.data),
        // }).then(({ json }) => ({ data: json })),

    // updateMany: (resource, params) => {
    //     const query = {
    //         filter: JSON.stringify({ id: params.ids}),
    //     };
    //     return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
    //         method: 'PUT',
    //         body: JSON.stringify(params.data),
    //     }).then(({ json }) => ({ data: json }));
    // },

    create: async (resource, params) => {
        console.log("create");
        let url = `${apiUrl}recipes`

        // console.log(params.data)

        const is_month = Array(12).fill(0);

        // is_month_listの月を1に設定
        params.data.is_month_list.forEach(month => {
            is_month[month - 1] = 1; // 月は1から始まるため、indexはmonth-1となる
        });
        let body = {
            // id: params.data.id,
            // uid: params.data.uid,
            title: params.data.title,
            category: params.data.category,
            genre: params.data.genre,
            is_month: is_month,
            ingredients: params.data.ingredients,
            nutritions: params.data.nutritions,
            date: [],
            // date: params.data.date,
            // active: true
        }
        // console.log(body)

        let json = await fetchData(url, user, 'POST', body)
        return  {data: {...params.data, id: json.id }}
    },
    delete: async (resource, params) => {
        let url = `${apiUrl}recipes/${params.id}/`
        let json = await fetchData(url, user, 'DELETE')
        return  {data: {json}}
    },
    deleteMany: async (resource, params) => {
        const deletePromises = params.ids.map(async (id) => {
            const url = `${apiUrl}recipes/${id}/`;
            try {
              const json = await fetchData(url, user, 'DELETE');
              return { id, success: true, data: json };
            } catch (error) {
              return { id, success: false, error };
            }
          });
        
          const results = await Promise.all(deletePromises);
        
          return { data: results };
    },
});
