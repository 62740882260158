import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navigation from '../contents/NavigationForAdmin'
import MenuForm from '../contents/MenuForm'
import {
    Admin,
    Resource,
    ListGuesser,
    EditGuesser,
    ShowGuesser,
  } from "react-admin";
import { dataProvider } from "./dataProvider";
import { BrowserRouter } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Layout } from 'react-admin';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import {TitlePortal } from 'react-admin';
import { Menu } from 'react-admin';
import RiceBowlIcon from '@mui/icons-material/RiceBowl';
import SetMealIcon from '@mui/icons-material/SetMeal';
import EggAltIcon from '@mui/icons-material/EggAlt';
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import CakeIcon from '@mui/icons-material/Cake';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';

import { Padding } from "@mui/icons-material";
import { AllEdit, AllList, AllShow, AllCreate, AllYokosukaList, AllYokosukaShow } from "../tables/all";
import Footer from "../contents/Footer";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Create, SimpleForm, TextInput, NumberInput } from 'react-admin';

import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  getAuth,
  sendEmailVerification
} from "firebase/auth";
import { auth } from "../../firebase.js";

const GuestMenu = (props) => {

  return (
    <Menu {...props} sx={{width: 20}}>
      <Box
        sx={{
          marginTop: 5,
          marginBottom: 1,
        }}
      >
        <Menu.Item to="/school/recipes/all_yokosuka" primaryText="全て(横須賀)" leftIcon={<DashboardIcon />}/>
        <Menu.Item to="/school/recipes/staple_yokosuka" primaryText="主食(横須賀)" leftIcon={<RiceBowlIcon />}/>
        <Menu.Item to="/school/recipes/main_yokosuka" primaryText="主菜(横須賀)" leftIcon={<SetMealIcon />}/>
        <Menu.Item to="/school/recipes/side_yokosuka" primaryText="副菜(横須賀)" leftIcon={<TakeoutDiningIcon />}/>
        <Menu.Item to="/school/recipes/soup_yokosuka" primaryText="汁物(横須賀)" leftIcon={<SoupKitchenIcon />}/>
        <Menu.Item to="/school/recipes/dessert_yokosuka" primaryText="デザート(横須賀)" leftIcon={<CakeIcon />}/>
      </Box>
    </Menu>
  );
};

const MyMenu = (props) => {
  // user は props 経由で受け取ることになります

  return (
    <Menu {...props} sx={{width: 20}}>
      <Box
        sx={{
          marginTop: 5,
          marginBottom: 1,
        }}
      >
        <Menu.Item to="/school/recipes/all" primaryText="全て" leftIcon={<DashboardIcon />}/>
        <Menu.Item to="/school/recipes/staple" primaryText="主食" leftIcon={<RiceBowlIcon />}/>
        <Menu.Item to="/school/recipes/main" primaryText="主菜" leftIcon={<SetMealIcon />}/>
        <Menu.Item to="/school/recipes/side" primaryText="副菜" leftIcon={<TakeoutDiningIcon />}/>
        <Menu.Item to="/school/recipes/soup" primaryText="汁物" leftIcon={<SoupKitchenIcon />}/>
        <Menu.Item to="/school/recipes/dessert" primaryText="デザート" leftIcon={<CakeIcon />}/>
        <hr/>
        <Menu.Item to="/school/recipes/all_yokosuka" primaryText="全て(横須賀)" leftIcon={<DashboardIcon />}/>
        <Menu.Item to="/school/recipes/staple_yokosuka" primaryText="主食(横須賀)" leftIcon={<RiceBowlIcon />}/>
        <Menu.Item to="/school/recipes/main_yokosuka" primaryText="主菜(横須賀)" leftIcon={<SetMealIcon />}/>
        <Menu.Item to="/school/recipes/side_yokosuka" primaryText="副菜(横須賀)" leftIcon={<TakeoutDiningIcon />}/>
        <Menu.Item to="/school/recipes/soup_yokosuka" primaryText="汁物(横須賀)" leftIcon={<SoupKitchenIcon />}/>
        <Menu.Item to="/school/recipes/dessert_yokosuka" primaryText="デザート(横須賀)" leftIcon={<CakeIcon />}/>
      </Box>
    </Menu>
  );
};

//   const MyLayout = (props) => <Layout {...props} appBar={MyAppBar} />;

export const RecipeList = () => {
  const [user, setUser] = useState(null)
  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      // console.log(currentUser);
    });
    }, []);
    const MyLayout = (props) => <Layout {...props} appBar={Navigation} menu={user ? MyMenu : GuestMenu}/>;

  return (
  <>
  {/* {user && (
    <a href="http://localhost:8000/files/sample_recipes_data.xlsx">一括レシピデータ追加</a>
  )} */}
    <Admin layout={MyLayout} basename="/school/recipes" dataProvider={dataProvider(user)}>
        {/* <Admin basename="/recipes" dataProvider={dataProvider}> */}
        {user && (
          <>
          
        <Resource
        name="all"
        list={AllList}
        edit={AllEdit}
        show={AllShow}
        create={AllCreate}
        />
        <Resource
        name="staple"
        list={AllList}
        edit={AllEdit}
        show={AllShow}
        create={AllCreate}
        />
        <Resource
        name="main"
        list={AllList}
        edit={AllEdit}
        show={AllShow}
        create={AllCreate}
        />
        <Resource
        name="side"
        list={AllList}
        edit={AllEdit}
        show={AllShow}
        create={AllCreate}
        />
        <Resource
        name="soup"
        list={AllList}
        edit={AllEdit}
        show={AllShow}
        create={AllCreate}
        />
        <Resource
        name="dessert"
        list={AllList}
        edit={AllEdit}
        show={AllShow}
        create={AllCreate}
        />
          </>
        )}
       
        <Resource
        name="all_yokosuka"
        list={AllYokosukaList}
        // edit={AllEdit}
        show={AllYokosukaShow}
        />
        <Resource
        name="staple_yokosuka"
        list={AllYokosukaList}
        // edit={AllEdit}
        show={AllYokosukaShow}
        />
        <Resource
        name="main_yokosuka"
        list={AllYokosukaList}
        // edit={AllEdit}
        show={AllYokosukaShow}
        />
        <Resource
        name="side_yokosuka"
        list={AllYokosukaList}
        // edit={AllEdit}
        show={AllYokosukaShow}
        />
        <Resource
        name="soup_yokosuka"
        list={AllYokosukaList}
        // edit={AllEdit}
        show={AllYokosukaShow}
        />
        <Resource
        name="dessert_yokosuka"
        list={AllYokosukaList}
        // edit={AllEdit}
        show={AllYokosukaShow}
        />
    </Admin>
  <Footer/>
  </>
  );
}