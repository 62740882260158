import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, Container, Paper, Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Navigation from '../contents/Navigation'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputAdornment,
} from '@mui/material';
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  getAuth,
  sendEmailVerification
} from "firebase/auth";
import { auth } from "../../firebase.js";
import Footer from "../contents/Footer";


// const api_url = "http://localhost:8000/"
const api_url = "https://api.menu-recommend.com/"

const theme = createTheme();

const CostList = () => {
  const [data, setData] = useState([]);
  const [user, setUser] = useState(null);
  // const [newFood, setNewFood] = useState({ name: '', cost: '' });

  const fetchData = async (user=null) => {
    try {
      const response = await fetch(`${api_url}school/yokosuka_costs`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      const formattedData = result.map(item => ({
        id: item.id,
        foodName: item.food.name,
        cost: item.cost,
        food_id: item.food_id
      }));
      // ログインしていたらユーザの食材も取ってくる
      if(user){
          const response = await fetch(`${api_url}school/costs`, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${user.accessToken}`,
            },
          });
    
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
    
          const result = await response.json();
    
          // console.log(formattedData)
          
          const mergedList = formattedData.map((item1) => {
            const matchingItem = result.find((item2) => item1.food_id === item2.food_id);
          
            if (matchingItem) {
              // food_idが同じアイテムがある場合、dataのcostとidをresultの値で更新
              return { ...item1, id: matchingItem.id, cost: matchingItem.cost };
            }
          
            // food_idが一致するアイテムがない場合、そのまま採用
            return item1;
          });
          // console.log(mergedList);
          
          // resultに存在してdataにはないアイテムを追加
          result.forEach((item2) => {
            if (!formattedData.some((item1) => item1.food_id === item2.food_id)) {
              mergedList.push({
                id: item2.id,
                foodName: item2.food.name,
                cost: item2.cost,
                food_id: item2.food_id
              });
            }
          });
          
          
          // console.log(mergedList);
    
          setData(mergedList);
      }
      else{
        setData(formattedData);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    // fetchYokosukaData();
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      fetchData(currentUser);
      // console.log(currentUser)
  });
  }, []);
  
  const [changedRows, setChangedRows] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');

  const handleCostChange = (id, newCost) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.id === id
          ? { ...item, cost: newCost, changed: true } // 変更があったことをマーク
          : item
      )
    );
    setChangedRows((prevChangedRows) =>
      prevChangedRows.includes(id) ? prevChangedRows : [...prevChangedRows, id]
    );
  };
  const handleSave = async (row) => {
    let id = row.id
    console.log(`費用が更新されました。ID: ${JSON.stringify(row)}`);
    // 保存処理を追加（例: サーバーにデータを送信する）
    let body = {
      // id: params.data.id,
      food_id: Number(row.food_id),
      cost: parseFloat(row.cost)
    }
    // console.log(body)

    try {
      const response = await fetch(`${api_url}school/costs/${row.id}/`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify(body)
      });

      const result = await response.json();   
      console.log(result)       
      
    } catch (error) {
    console.error("Error fetching data:", error);
    }

    // 変更が完了したら変更マークをクリア
    setData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, changed: false } : item
      )
    );
    setChangedRows((prevChangedRows) =>
      prevChangedRows.filter((rowId) => rowId !== id)
    );
  };


  const handleSort = (column) => {
    if (sortBy === column) {
      // 既に同じ列が選択されている場合、ソート順を切り替える
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // 別の列が選択された場合、新しい列で昇順にソート
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const sortedData = [...data].sort((a, b) => {
    if (sortBy) {
      // Check if a[sortBy] and b[sortBy] are strings
      if (typeof a[sortBy] === 'string' && typeof b[sortBy] === 'string') {
        // Sort the strings using localeCompare
        return sortOrder === 'asc'
          ? a[sortBy].localeCompare(b[sortBy])
          : b[sortBy].localeCompare(a[sortBy]);
      } else {
        // If not strings, fallback to basic comparison
        return sortOrder === 'asc' ? a[sortBy] - b[sortBy] : b[sortBy] - a[sortBy];
      }
    } else {
      return 0;
    }
  });

  return (
    <ThemeProvider theme={theme}>
        <Navigation/>
        {/* <div>
      <h1>Excel Download Example</h1>
      <button onClick={handleDownload}>Download Excel</button>
    </div> */}
    {!user && (
        <Grid sx={{ flexGrow: 1, padding: 2 }} container justifyContent="center" alignItems="center" spacing={2} rowSpacing={2}> 
        <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{pt: 3}}>
            <Grid item>
            {/* <Grid item> */}
                <Typography variant="body">
                    ユーザ登録をすると費用の変更ができます
                </Typography>
            </Grid>
            </Grid>
        </Grid>
        </Grid>
    )}

      <Container sx={{pt: 3}}>
      <Typography variant="h5" align="center" gutterBottom sx={{p: 1}}>
          食材の費用管理
      </Typography>
      <TableContainer component={Paper} sx={{ maxHeight: 580 }}>
      <Table size="small" stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell onClick={() => handleSort('foodName')}>食材名</TableCell>
            <TableCell onClick={() => handleSort('cost')}>1 g あたりの費用</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.foodName}</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  value={row.cost}
                  onChange={(e) => handleCostChange(row.id, e.target.value)}
                  style={{ width: '150px'}} // スタイルを追加
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">円</InputAdornment>
                    ),
                  }}
                  disabled={!user}
                />
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSave(row)}
                  disabled={!changedRows.includes(row.id) || !user}
                >
                  保存
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      </Container>
      <Footer/>
    </ThemeProvider>
  );
};

export default CostList;
