import React, { useState, useEffect } from "react";
import Navigation from '../contents/Navigation';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';
import Footer from "../contents/Footer";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Link } from "react-router-dom";

// https://kiyaku.jp/hinagata/gp.html

export const Terms = () => {
  const listItemStyle = {
    marginBottom: '10px',
    marginTop: '10px',
  };
  // 当社 -> 運営
  // サービス -> 給食献立推薦
  return (
    <>
    
    <Navigation/>
    <Paper variant="outlined" sx={{p: 5, m: 5}}>
    <Typography variant="h4" align="left" sx={{pt: 1, pb: 2}}>利用規約</Typography>
    この利用規約（以下、「本規約」といいます。）は、本アプリケーションの運営者（以下、「運営」といいます。）がこのウェブサイト上で提供するサービス（以下、「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下、「ユーザー」といいます。）には、本規約に従って、本サービスをご利用いただきます。

    <Typography variant="h5" align="left" sx={{pt: 4, pb: 0}}>第1条（適用）</Typography>
    <List>
      <ListItem>
        <ListItemText>
          本規約は、ユーザーと運営との間の本サービスの利用に関わる一切の関係に適用されるものとします。
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          運営は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下、「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          本規約の規定が前条の個別規定の規定と矛盾する場合には、個別規定において特段の定めなき限り、個別規定の規定が優先されるものとします。
        </ListItemText>
      </ListItem>
    </List>

    <Typography variant="h5" align="left" sx={{pt: 4, pb: 0}}>第2条（利用登録）</Typography>
    <List>
      <ListItem>
        <ListItemText>
        本サービスにおいては、登録希望者が本規約に同意の上、運営の定める方法によって利用登録を申請し、運営がこれを承認することによって、利用登録が完了するものとします。
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
        運営は、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。
        <ol>
          <li style={listItemStyle}>利用登録の申請に際して虚偽の事項を届け出た場合</li>
          <li style={listItemStyle}>本規約に違反したことがある者からの申請である場合</li>
          <li style={listItemStyle}>その他、運営が利用登録を相当でないと判断した場合</li>
        </ol>
        </ListItemText>
      </ListItem>
    </List>

    <Typography variant="h5" align="left" sx={{pt: 4, pb: 0}}>第3条（ユーザーIDおよびパスワードの管理）</Typography>
    <List>
      <ListItem>
        <ListItemText>
        ユーザーは、自己の責任において、本サービスのユーザーIDおよびパスワードを適切に管理するものとします。
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
        ユーザーは、いかなる場合にも、ユーザーIDおよびパスワードを第三者に譲渡または貸与し、もしくは第三者と共用することはできません。運営は、ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には、そのユーザーIDを登録しているユーザー自身による利用とみなします。
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
        ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は、運営に故意又は重大な過失がある場合を除き、運営は一切の責任を負わないものとします。
        </ListItemText>
      </ListItem>
    </List>

    <Typography variant="h5" align="left" sx={{pt: 4, pb: 0}}>第4条（利用料金）</Typography>
    <List>
      <ListItem>
        <ListItemText>
          本アプリケーションは無料でご利用いただけます。
        </ListItemText>
      </ListItem>
    </List>

    <Typography variant="h5" align="left" sx={{pt: 4, pb: 0}}>第5条（禁止事項）</Typography>
    <List>
      <ListItem>
        <ListItemText>
        ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。
        <ol>
          <li style={listItemStyle}>法令または公序良俗に違反する行為</li>
          <li style={listItemStyle}>犯罪行為に関連する行為</li>
          <li style={listItemStyle}>本サービスの内容等、本サービスに含まれる著作権、商標権ほか知的財産権を侵害する行為</li>
          <li style={listItemStyle}>運営、ほかのユーザー、またはその他第三者のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為</li>
          <li style={listItemStyle}>本サービスによって得られた情報を商業的に利用する行為</li>
          <li style={listItemStyle}>本サービスの運営を妨害するおそれのある行為</li>
          <li style={listItemStyle}>不正アクセスをし、またはこれを試みる行為</li>
          <li style={listItemStyle}>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
          <li style={listItemStyle}>不正な目的を持って本サービスを利用する行為</li>
          <li style={listItemStyle}>本サービスの他のユーザーまたはその他の第三者に不利益、損害、不快感を与える行為</li>
          <li style={listItemStyle}>他のユーザーに成りすます行為</li>
          <li style={listItemStyle}>運営が許諾しない本サービス上での宣伝、広告、勧誘、または営業行為</li>
          <li style={listItemStyle}>運営のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為</li>
          <li style={listItemStyle}>その他、運営が不適切と判断する行為</li>
        </ol>
        </ListItemText>
      </ListItem>
    </List>

    <Typography variant="h5" align="left" sx={{pt: 4, pb: 0}}>第6条（本サービスの提供の停止等）</Typography>
    <List>
      <ListItem>
        <ListItemText>
        運営は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
        <ol>
          <li style={listItemStyle}>本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</li>
          <li style={listItemStyle}>地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合</li>
          <li style={listItemStyle}>コンピュータまたは通信回線等が事故により停止した場合</li>
          <li style={listItemStyle}>その他、運営が本サービスの提供が困難と判断した場合</li>
        </ol>
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
        運営は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。
        </ListItemText>
      </ListItem>
    </List>

    <Typography variant="h5" align="left" sx={{pt: 4, pb: 0}}>第7条（入力データの取り扱い）</Typography>
    <List>
      <ListItem>
        <ListItemText>
        本サービスの利用では、ユーザーがレシピデータを入力・保存することができます（以下、「入力データ」といいます）。
        本規約に基づきユーザーから許諾された範囲を除き、運営は、入力データに関するいかなる権利も取得しません。
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
        運営は、ユーザーの承諾を得ることなく、サーバの故障・停止時の復旧の便宜に備えて入力データを任意でバックアップできるものとします。
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          運営は、献立推薦サービスの提供のための使用を除いて、入力データに対し、アクセスを行うことはありません。
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          運営は、ユーザーの承諾を得ることなく、入力データを第三者に開示・公開することはありません。ただし、法令に従った要請（捜査関係事項照会書による要請を含む）や法令の手続上必要とされる場合については、ユーザーの承諾なく、全部または一部の入力データを開示・公開することがあります。
        </ListItemText>
      </ListItem>
    </List>

    <Typography variant="h5" align="left" sx={{pt: 4, pb: 0}}>第8条（利用制限および登録抹消）</Typography>
    <List>
      <ListItem>
        <ListItemText>
        運営は、ユーザーが以下のいずれかに該当する場合には、事前の通知なく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限し、またはユーザーとしての登録を抹消することができるものとします。
        <ol>
          <li style={listItemStyle}>本規約のいずれかの条項に違反した場合</li>
          <li style={listItemStyle}>登録事項に虚偽の事実があることが判明した場合</li>
          <li style={listItemStyle}>運営からの連絡に対し、一定期間返答がない場合</li>
          <li style={listItemStyle}>本サービスについて、最終の利用から一定期間利用がない場合</li>
          <li style={listItemStyle}>その他、運営が本サービスの利用を適当でないと判断した場合</li>
        </ol>
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
        運営は、本条に基づき運営が行った行為によりユーザーに生じた損害について、一切の責任を負いません。
        </ListItemText>
      </ListItem>
    </List>

    <Typography variant="h5" align="left" sx={{pt: 4, pb: 0}}>第9条（退会）</Typography>
    <List>
      <ListItem>
        <ListItemText>
          ユーザーは、運営の定める退会手続により、本サービスから退会できるものとします。
        </ListItemText>
      </ListItem>
    </List>

    <Typography variant="h5" align="left" sx={{pt: 4, pb: 0}}>第10条（保証の否認および免責事項）</Typography>
    <List>
      <ListItem>
        <ListItemText>
        運営は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
        運営は、本サービスに起因してユーザーに生じたあらゆる損害について、運営の故意又は重過失による場合を除き、一切の責任を負いません。
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
        前項ただし書に定める場合であっても、運営は、運営の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（運営またはユーザーが損害発生につき予見し、または予見し得た場合を含みます。）について一切の責任を負いません。
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
        運営は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
        </ListItemText>
      </ListItem>
    </List>

    <Typography variant="h5" align="left" sx={{pt: 4, pb: 0}}>第11条（サービス内容の変更等）</Typography>
    <List>
      <ListItem>
        <ListItemText>
        運営は、ユーザーへの事前の告知をもって、本サービスの内容を変更、追加または廃止することがあり、ユーザーはこれを承諾するものとします。
        </ListItemText>
      </ListItem>
    </List>

    <Typography variant="h5" align="left" sx={{pt: 4, pb: 0}}>第12条（利用規約の変更）</Typography>
    <List>
      <ListItem>
        <ListItemText>
        運営は以下の場合には、ユーザーの個別の同意を要せず、本規約を変更することができるものとします。
        <ol>
          <li style={listItemStyle}>本規約の変更がユーザーの一般の利益に適合するとき。</li>
          <li style={listItemStyle}>本規約の変更が本サービス利用契約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性その他の変更に係る事情に照らして合理的なものであるとき。</li>
        </ol>
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
        運営はユーザーに対し、前項による本規約の変更にあたり、事前に、本規約を変更する旨及び変更後の本規約の内容並びにその効力発生時期を通知します。
        </ListItemText>
      </ListItem>
    </List>

    <Typography variant="h5" align="left" sx={{pt: 4, pb: 0}}>第13条（個人情報の取扱い）</Typography>
    <List>
      <ListItem>
        <ListItemText>
        運営は、本サービスの利用によって取得する個人情報については、<Link to="/school/privacy">プライバシーポリシー</Link>に従い適切に取り扱うものとします。
        </ListItemText>
      </ListItem>
    </List>

    <Typography variant="h5" align="left" sx={{pt: 4, pb: 0}}>第14条（通知または連絡）</Typography>
    <List>
      <ListItem>
        <ListItemText>
        ユーザーと運営との間の通知または連絡は、運営の定める方法によって行うものとします。運営は、ユーザーから運営が別途定める方式に従った変更届け出がない限り、現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い、これらは発信時にユーザーへ到達したものとみなします。
        </ListItemText>
      </ListItem>
    </List>

    <Typography variant="h5" align="left" sx={{pt: 4, pb: 0}}>第15条（権利義務の譲渡の禁止）</Typography>
    <List>
      <ListItem>
        <ListItemText>
        ユーザーは、運営の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
        </ListItemText>
      </ListItem>
    </List>

    <Typography variant="h5" align="left" sx={{pt: 4, pb: 0}}>第16条（準拠法・裁判管轄）</Typography>
    <List>
      <ListItem>
        <ListItemText>
        本規約の解釈にあたっては、日本法を準拠法とします。
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
        本サービスに関して紛争が生じた場合には、運営の所在地を管轄する裁判所を専属的合意管轄とします。
        </ListItemText>
      </ListItem>
    </List>

    <Typography align="right" sx={{pt: 4, pb: 0}}>以上</Typography>
    <Typography align="right" sx={{pt: 4, pb: 0}}>2024年2月1日制定</Typography>

    </Paper>
    <Footer/>
    </>
  );
}