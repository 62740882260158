import React, { useState, useEffect  } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  getAuth,
  sendEmailVerification
} from "firebase/auth";
import { auth } from "../../firebase.js";
import Navigation from '../contents/Navigation'

// const api_url = "http://localhost:8000/"
const api_url = "https://api.menu-recommend.com/"


const category_name = ["主菜", "副菜", "主食", "汁物", "デザート"]
const category_name_en = ["main", "side", "staple", "soup", "dessert"]
const category_color = ["#ffeddb", "#edffdb", "#ffdbed", "#dbedff", "#ffffdb"]

const genre_name = ["和風", "洋風", "中華風", "韓国風", "エスニック"]
const genre_color = ["#C66600", "#3366ff", "#ff7f7f", "#387d39", "#6f5436"]

const nutrition_unit = {
    "エネルギー": "kcal", "たんぱく質": "g", "脂質": "g", "ナトリウム": "mg"
}

const IngredientsTable = ({ ingredients }) => {
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Energy</TableCell>
              <TableCell align="right">Protein</TableCell>
              <TableCell align="right">Lipid</TableCell>
              <TableCell align="right">Sodium</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ingredients.map((ingredient, index) => (
              <TableRow key={index}>
                <TableCell>{ingredient.name}</TableCell>
                <TableCell align="right">{ingredient.amount}</TableCell>
                <TableCell align="right">{ingredient.energy.toFixed(1)}</TableCell>
                <TableCell align="right">{ingredient.protein.toFixed(1)}</TableCell>
                <TableCell align="right">{ingredient.lipid.toFixed(1)}</TableCell>
                <TableCell align="right">{ingredient.sodium.toFixed(1)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

const RecipeDetailsCard = ({ recipe }) => {
  return (
    <Card>
      <CardContent>
        <List>
          <ListItem>
            <ListItemText primary="Title" secondary={recipe.title} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Category"
              secondary={
                <Chip
                  label={category_name[recipe.category]}
                  sx={{
                    backgroundColor: category_color[recipe.category],
                    edgecolor: "#000000"
                  }}
                />
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Genre"
              secondary={
                <Chip
                  label={genre_name[recipe.genre]}
                  sx={{
                    backgroundColor: genre_color[recipe.genre],
                    color: "#ffffff"
                  }}
                />
              }
            />
          </ListItem>
          {/* 他の詳細情報も同様に表示 */}
          <ListItem>
            <ListItemText
              primary="Nutritions"
              secondary={
                <List>
                  {Object.entries(recipe.nutritions).map(([key, value]) => (
                    <ListItem key={key}>
                      <ListItemText primary={key} secondary={value.toFixed(1)} />
                    </ListItem>
                  ))}
                </List>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Ingredients"
              secondary={
                <IngredientsTable ingredients={recipe.ingredients} />
              }
            />
          </ListItem>
          {/* <ListItem>
            <ListItemText primary="Cost" secondary={recipe.cost} />
          </ListItem> */}
        </List>
      </CardContent>
    </Card>
  );
};

const RecipeDetailsDialog = ({ open, handleClose, recipe, year, month, day, method, menu_id, user}) => {
  const navigate = useNavigate();
  const handleMenuEdit = async () => {
    // console.log("入った")
    if(method=="edit"){
      try {
        const response = await fetch(`${api_url}school/menus`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.accessToken}`
          },
          body: JSON.stringify({
            "id": menu_id,
            "recipe_id": recipe.id
          }),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        // console.log('PUT request successful');
        // ページをリダイレクト
        navigate(`/school/menu-table?year=${year}&month=${month}&day=${day}`); // ここでリダイレクト先のURLを指定
      } catch (error) {
        console.error('Error:', error.message);
        // エラーが発生した場合の処理をここに追加できます
      }
  };
  if(method=="add"){
    try {
      const response = await fetch(`${api_url}school/menus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${user.accessToken}`
        },
        body: JSON.stringify({
          "date": `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`,
          "recipe_id": recipe.id
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // console.log('POST request successful');
      navigate(`/school/menu-table?year=${year}&month=${month}&day=${day}`); 
    } catch (error) {
      console.error('Error:', error.message);
      // エラーが発生した場合の処理をここに追加できます
    }
};};
    return (
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle>{recipe.title} の詳細</DialogTitle>
        <Button onClick={()=> handleMenuEdit()}>このレシピを採用する</Button>
        <DialogContent>
         <RecipeDetailsCard recipe={recipe} />
        </DialogContent>
      </Dialog>
    );
  };

  
 const RecipeDataGrid = () => {
    const [searchParams] = useSearchParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);
    const [recipes, setRecipes] = useState([]);

    const year = searchParams.get('year');
    const month = searchParams.get('month');
    const day = searchParams.get('day');
    const method = searchParams.get('method');
    const menu_id = searchParams.get('id');

  const fetchData = async (user) => {
        const yokosuka_response = await fetch(`${api_url}school/yokosuka_recipes`, {
            method: 'GET',
            // headers: {
            //     // 'Authorization': `Bearer ${user.accessToken}`,
            // }
        });

        const yokosuka_result = await yokosuka_response.json();
        const response = await fetch(`${api_url}school/recipes`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${user.accessToken}`,
            }
        });
        // console.log(yokosuka_result)
        const result = await response.json();
        // console.log(result)
        let recipes = [...yokosuka_result, ...result]
        // console.log(recipes)
        setRecipes(recipes);
  };

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser);
        if(currentUser){
            fetchData(currentUser)
        }
        // console.log(currentUser)
    });
    

}, []);

    // console.log(year, month, day, method);
    const transformedRecipes = recipes.map(row => {
        const { nutritions, ...rest } = row;
        return {
          ...rest,
        //   id: row.id,
            nutritions: nutritions,
            脂質: nutritions.脂質,
            たんぱく質: nutritions.たんぱく質,
            エネルギー: nutritions.エネルギー,
            ナトリウム: nutritions.ナトリウム,
        };
      });
    
    // console.log(transformedRecipes)
    
    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'title', headerName: 'Title', width: 200,
        renderCell: (params) => (
            <Link
                component="button"
                variant="body2"
                onClick={() => handleTitleClick(params)}
                >
                {params.value}
            </Link>
            ),},
        {   field: 'category',
            headerName: 'Category',
            width: 80,
            renderCell: (params) => (
            <Chip label={category_name[params.value]} variant="outlined" sx={{
                backgroundColor: category_color[params.value],
                edgecolor: "#000000"
            }} 
            onClick={() => handleCategoryClick(params.value)}
            />
            ),
            
        },
        { 
            field: 'genre',
            headerName: 'Genre', 
            width: 80,
            renderCell: (params) => (
                <Chip label={genre_name[params.value]} variant="outlined" sx={{
                    backgroundColor: genre_color[params.value],
                    edgecolor: "#000000"
                }}
                onClick={() => handleGenreClick(params.value)}
                /> 
            )
        },
        { field: 'エネルギー', headerName: 'エネルギー\n(kcal)' },
        { field: '脂質', headerName: '脂質(g)', width: 100 },
        { field: 'たんぱく質', headerName: 'たんぱく質(g)', width: 130 },
        { field: 'ナトリウム', headerName: 'ナトリウム(mg)', width: 150 },
    ];
    
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedGenre, setSelectedGenre] = useState(null);
    const [selectedRecipe, setSelectedRecipe] = useState({"title": "あ"});
    const [isDetailsDialogOpen, setDetailsDialogOpen] = useState(false);

    const handleTitleClick = (params) => {
        // レシピの詳細情報をセットしてダイアログを開く
        setSelectedRecipe(params.row);
        // console.log(params.row);
        setDetailsDialogOpen(true);
      };

    const handleRowClick = (params) => {
        // レシピの詳細情報をセットしてダイアログを開く
        setSelectedRecipe(params.row);
        // console.log(params.row);
        setDetailsDialogOpen(true);
      };
    
      const handleCloseDetailsDialog = () => {
        // ダイアログを閉じる
        setDetailsDialogOpen(false);
      };
    const handleCategoryClick = (categoryValue) => {
        setDetailsDialogOpen(false);
        setSelectedCategory(categoryValue);
      };

      const handleGenreClick = (genreValue) => {
        setDetailsDialogOpen(false);
        setSelectedGenre(genreValue);
        // setSelectedCategory(null); // ジャンルが変更されたらカテゴリーのフィルターを解除
      };

      const handleClearCategoryFilter = () => {
        setSelectedCategory(null);
      };
      const handleClearGenreFilter = () => {
        setSelectedGenre(null);
      };
      const filteredRecipes = transformedRecipes
      .filter(recipe => selectedCategory === null || recipe.category === selectedCategory)
      .filter(recipe => selectedGenre === null || recipe.genre === selectedGenre);
    return (
    //   <div style={{ height: '90%', width: '100%' }}>
    <div>
      <Navigation/>
      <Button onClick={handleClearCategoryFilter}>
        Categoryのフィルター解除
        {/* <Chip label={genre_name[selectedCategory]} variant="outlined" sx={{
            backgroundColor: genre_color[selectedCategory],
            edgecolor: "#000000"
        }}/> */}
      </Button>
      <Button onClick={handleClearGenreFilter}>
        Genreのフィルター解除
      </Button>
        <DataGrid
          rows={filteredRecipes}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10]}
        //   checkboxSelection
          sx={{p: 3}}
        //   onRowClick={handleRowClick}
        />
        <RecipeDetailsDialog
        open={isDetailsDialogOpen}
        handleClose={handleCloseDetailsDialog}
        recipe={selectedRecipe}
        year={year}
        month={month}
        day={day}
        method={method}
        menu_id={menu_id}
        user={user}
      />
    </div>
    );
  };

  export default RecipeDataGrid;