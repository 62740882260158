import { 
    Create, 
    Datagrid, 
    Edit, 
    List, 
    ReferenceField, 
    ReferenceInput, 
    SimpleForm, 
    TextField, 
    TextInput, 
    NumberField,
    DateInput,
    NumberInput,
    ChipField,
    FunctionField,
    ArrayInput,
    SimpleFormIterator,
    SelectInput,
    Show,
    SimpleShowLayout,
    ArrayField,
    CheckboxGroupInput,
    DateField,
    BulkDeleteButton,
    useRecordContext, 
    SimpleList} from 'react-admin';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { CreateButton, ExportButton, TopToolbar, FilterButton, ListButton, ShowButton, EditButton } from 'react-admin';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Link } from "react-router-dom";
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';


const QuickFilter = ({ label, defaultValue }) => {
    // const translate = useTranslate();
    return <Chip sx={{ marginBottom: 1 }} label={label} />;
};

const AllFilters = [
    <TextInput source="q" label="Search" alwaysOn />,
    <QuickFilter source="filter_genre.japanese" label="和風" defaultValue={true} />,
    <QuickFilter source="filter_genre.western" label="洋風" defaultValue={true} />,
    <QuickFilter source="filter_genre.chinese" label="中華風" defaultValue={true} />,
    <QuickFilter source="filter_genre.korean" label="韓国風" defaultValue={true} />,
    <QuickFilter source="filter_genre.esnic" label="エスニック" defaultValue={true} />,
];

const category_name = ["主菜", "副菜", "主食", "汁物", "デザート"]
const category_name_en = ["main", "side", "staple", "soup", "dessert"]
const category_color = ["#ffeddb", "#edffdb", "#ffdbed", "#dbedff", "#ffffdb"]

const genre_name = ["和風", "洋風", "中華風", "韓国風", "エスニック"]
const genre_color = ["#C66600", "#3366ff", "#ff7f7f", "#387d39", "#6f5436"]

const nutrition_unit = {
    "エネルギー": "kcal", "たんぱく質": "g", "脂質": "g", "ナトリウム": "mg"
}
const required = () => (value) => value ? undefined : "myroot.validation.required";

const MyHiddenField = ({ record, ...props }) => (
    <span>{record ? record.myHiddenField : null}</span>
);

const PostListActions = (user) => (
    <TopToolbar>
        <FilterButton/>
        <CreateButton />
        <Button component={Link} to="/school/add-recipes"><LibraryAddIcon/>レシピ一括登録</Button>
        {/* <ExportButton /> */}
    </TopToolbar>
);

const PostEditActions = () => (
    <TopToolbar>
        <ListButton />
        <ShowButton />
    </TopToolbar>
);

const PostCreateActions = () => (
    <TopToolbar>
        <ListButton />
    </TopToolbar>
);

const PostShowActions = () => (
    <TopToolbar>
        <ListButton />
        <EditButton />
    </TopToolbar>
);

const PostYokosukaShowActions = () => (
    <TopToolbar>
        <ListButton />
        {/* <EditButton /> */}
    </TopToolbar>
);


const Empty = () => (
    // 親要素にも display: flex を追加
<Box display="flex" justifyContent="center" alignItems="center" height="40vh" width="100%">
    <Box textAlign="center" m={1}>
        <Typography variant="h4" paragraph>
            レシピデータがありません
        </Typography>
        {/* <Typography variant="body1">
            Create one or import from a file
        </Typography> */}
        <CreateButton />
        {/* モバイルでは非表示 */}
        <Button 
        component={Link} 
        to="/school/add-recipes"
        sx={{display: { xs: "none", sm: "none", md: "inline" }, pl: 4}}
        >
            <LibraryAddIcon/>レシピ一括登録</Button>
        </Box>
</Box>


);

export const AllList = () => (
    <List filters={AllFilters} actions={<PostListActions/>} empty={<Empty />} sx={{p: 5}}>
        <Datagrid rowClick="show">
            {/* <TextField source="id" /> */}
            <TextField source="title" />
            <FunctionField 
            label="Category" 
            render={record => 
                <Chip 
                label={category_name[record.category]} 
                sx={{
                    backgroundColor: category_color[record.category],
                    edgecolor: "#000000"
                }} 
                />} 
            />
            <FunctionField 
            label="Genre" 
            render={record => 
                <Chip 
                label={genre_name[record.genre]} 
                color="primary"
                sx={{
                    backgroundColor: genre_color[record.genre],
                    fontColor: "#ffffff"
                }} 
                
                />} 
            />
        </Datagrid>
    </List>
);


export const AllYokosukaList = () => (
    <List filters={AllFilters} sx={{p: 5}}>
        <Datagrid rowClick="show">
            {/* <TextField source="id" /> */}
            <TextField source="title" />
            <FunctionField 
            label="Category" 
            render={record => 
                <Chip 
                label={category_name[record.category]} 
                sx={{
                    backgroundColor: category_color[record.category],
                    edgecolor: "#000000"
                }} 
                />} 
            />
            <FunctionField 
            label="Genre" 
            render={record => 
                <Chip 
                label={genre_name[record.genre]} 
                color="primary"
                sx={{
                    backgroundColor: genre_color[record.genre],
                    fontColor: "#ffffff"
                }} 
                
                />} 
            />
        </Datagrid>
    </List>
);

// const AllTitle = () => {
//     const record = useRecordContext();
//     return <span>All {record ? `"${record.title}"` : ''}</span>
// };

export const AllEdit = () => {
    const choices = [
        { id: '1', name: '1月' },
        { id: '2', name: '2月' },
        { id: '3', name: '3月' },
        { id: '4', name: '4月' },
        { id: '5', name: '5月' },
        { id: '6', name: '6月' },
        { id: '7', name: '7月' },
        { id: '8', name: '8月' },
        { id: '9', name: '9月' },
        { id: '10', name: '10月' },
        { id: '11', name: '11月' },
        { id: '12', name: '12月' },
      ];
    return (
    <Edit actions={<PostEditActions/>}>
        <SimpleForm>
            <TextInput 
            source="title" 
            label="タイトル"
            sx={{width: '50ch'}}
            />
            <Grid item xs={12}>
                <Grid item>   
                    <Grid container sx={{ paddingBottom: 0.5 }} justifyContent="center" alignItems="center" spacing={3} rowSpacing={10}>
                        <Grid item>
                            <SelectInput source="category" label="カテゴリ" choices={[
                                { id: 0, name: category_name[0] },
                                { id: 1, name: category_name[1] },
                                { id: 2, name: category_name[2] },
                                { id: 3, name: category_name[3] },
                                { id: 4, name: category_name[4] },
                            ]} />
                        </Grid>
                        <Grid item>
                            <SelectInput source="genre" label="ジャンル" choices={[
                                { id: 0, name: genre_name[0] },
                                { id: 1, name: genre_name[1] },
                                { id: 2, name: genre_name[2] },
                                { id: 3, name: genre_name[3] },
                                { id: 4, name: genre_name[4] },
                            ]} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom>
                食材情報
            </Typography>
            <ArrayInput source="ingredients" label="">
                <SimpleFormIterator inline>
                    <TextInput source="name" label="食材名" helperText={false} />
                    <NumberInput 
                    source="amount" 
                    label="分量" 
                    helperText={false} 
                    InputProps={{
                        endAdornment: <InputAdornment position="end">g</InputAdornment>,
                        style: { width: "100px"},
                    }}
                    validate={required()} 
                    />
                    <NumberInput 
                    source="energy" 
                    label="エネルギー(任意)" 
                    helperText={false}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">kcal</InputAdornment>,
                        style: { width: "200px"},
                    }}
                     />
                    <NumberInput 
                    source="protein" 
                    label="たんぱく質(任意)" 
                    helperText={false} 
                    InputProps={{
                        endAdornment: <InputAdornment position="end">g</InputAdornment>,
                        style: { width: "200px"},
                    }}
                    />
                    <NumberInput 
                    source="lipid" 
                    label="脂質(任意)" 
                    helperText={false} 
                    InputProps={{
                        endAdornment: <InputAdornment position="end">g</InputAdornment>,
                        style: { width: "200px"},
                    }}
                    />
                    <NumberInput 
                    source="sodium" 
                    label="ナトリウム(任意)" 
                    helperText={false} 
                    InputProps={{
                        endAdornment: <InputAdornment position="end">mg</InputAdornment>,
                        style: { width: "200px"},
                    }}
                    />

                </SimpleFormIterator>
                    
            </ArrayInput>

            <Typography variant="h6" gutterBottom>
                栄養情報
            </Typography>
            <Grid item xs={12}>
                <Grid item>   
                    <Grid container sx={{ paddingBottom: 0.5 }} justifyContent="center" alignItems="center" spacing={3} rowSpacing={10}>
                        <Grid item>
                            <NumberInput 
                            source="nutritions.エネルギー"
                            label="エネルギー" 
                            helperText={false}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">kcal</InputAdornment>,
                                // style: { width: "120px"},
                            }}
                            />
                        </Grid>
                        <Grid item>
                            <NumberInput 
                            source="nutritions.たんぱく質"
                            label="たんぱく質" 
                            helperText={false} 
                            InputProps={{
                                endAdornment: <InputAdornment position="end">g</InputAdornment>,
                                // style: { width: "100px"},
                            }}
                            />
                        </Grid>
                        <Grid item>
                            <NumberInput 
                            source="nutritions.脂質"
                            label="脂質" 
                            helperText={false} 
                            InputProps={{
                                endAdornment: <InputAdornment position="end">g</InputAdornment>,
                                // style: { width: "100px"},
                            }}
                            />
                        </Grid>
                        <Grid item>
                            <NumberInput 
                            source="nutritions.ナトリウム"
                            label="ナトリウム" 
                            helperText={false} 
                            InputProps={{
                                endAdornment: <InputAdornment position="end">mg</InputAdornment>,
                                // style: { width: "100px"},
                            }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom sx={{pt: 3}}>
                献立に採用する月
            </Typography>
            <CheckboxGroupInput source="is_month_list" label="" choices={choices} optionValue="id" optionText="name" labelPlacement="bottom" />
            {/* <Typography variant="h6" gutterBottom>
                <br/>
                その他
            </Typography> */}
            {/* <TextInput source="id" disabled/> */}
            {/* <TextInput source="date" label="過去の献立の日付" disabled/> */}
            
        </SimpleForm>
    </Edit>
)};


export const AllCreate = () => {
    const choices = [
        { id: '1', name: '1月' },
        { id: '2', name: '2月' },
        { id: '3', name: '3月' },
        { id: '4', name: '4月' },
        { id: '5', name: '5月' },
        { id: '6', name: '6月' },
        { id: '7', name: '7月' },
        { id: '8', name: '8月' },
        { id: '9', name: '9月' },
        { id: '10', name: '10月' },
        { id: '11', name: '11月' },
        { id: '12', name: '12月' },
    ];
    return (
    <Create actions={<PostCreateActions/>}>
        <SimpleForm>
            <TextInput 
            source="title" 
            label="タイトル"
            sx={{width: '50ch'}}
            />
            <Grid item xs={12}>
                <Grid item>   
                    <Grid container sx={{ paddingBottom: 0.5 }} justifyContent="center" alignItems="center" spacing={3} rowSpacing={10}>
                        <Grid item>
                            <SelectInput source="category" label="カテゴリ" choices={[
                                { id: 0, name: category_name[0] },
                                { id: 1, name: category_name[1] },
                                { id: 2, name: category_name[2] },
                                { id: 3, name: category_name[3] },
                                { id: 4, name: category_name[4] },
                            ]} />
                        </Grid>
                        <Grid item>
                            <SelectInput source="genre" label="ジャンル" choices={[
                                { id: 0, name: genre_name[0] },
                                { id: 1, name: genre_name[1] },
                                { id: 2, name: genre_name[2] },
                                { id: 3, name: genre_name[3] },
                                { id: 4, name: genre_name[4] },
                            ]} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom>
                食材情報
            </Typography>
            <ArrayInput source="ingredients" label="">
                <SimpleFormIterator inline>
                    <TextInput source="name" label="食材名" helperText={false} />
                    <NumberInput 
                    source="amount" 
                    label="分量" 
                    helperText={false} 
                    InputProps={{
                        endAdornment: <InputAdornment position="end">g</InputAdornment>,
                        style: { width: "100px"},
                    }}
                    validate={required()} 
                    />
                    <NumberInput 
                    source="energy" 
                    label="エネルギー(任意)" 
                    helperText={false}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">kcal</InputAdornment>,
                        style: { width: "200px"},
                    }}
                     />
                    <NumberInput 
                    source="protein" 
                    label="たんぱく質(任意)" 
                    helperText={false} 
                    InputProps={{
                        endAdornment: <InputAdornment position="end">g</InputAdornment>,
                        style: { width: "200px"},
                    }}
                    />
                    <NumberInput 
                    source="lipid" 
                    label="脂質(任意)" 
                    helperText={false} 
                    InputProps={{
                        endAdornment: <InputAdornment position="end">g</InputAdornment>,
                        style: { width: "200px"},
                    }}
                    />
                    <NumberInput 
                    source="sodium" 
                    label="ナトリウム(任意)" 
                    helperText={false} 
                    InputProps={{
                        endAdornment: <InputAdornment position="end">mg</InputAdornment>,
                        style: { width: "200px"},
                    }}
                    />

                </SimpleFormIterator>
                    
            </ArrayInput>

            <Typography variant="h6" gutterBottom>
                栄養情報
            </Typography>
            <Grid item xs={12}>
                <Grid item>   
                    <Grid container sx={{ paddingBottom: 0.5 }} justifyContent="center" alignItems="center" spacing={3} rowSpacing={10}>
                        <Grid item>
                            <NumberInput 
                            source="nutritions.エネルギー"
                            label="エネルギー" 
                            helperText={false}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">kcal</InputAdornment>,
                                // style: { width: "120px"},
                            }}
                            />
                        </Grid>
                        <Grid item>
                            <NumberInput 
                            source="nutritions.たんぱく質"
                            label="たんぱく質" 
                            helperText={false} 
                            InputProps={{
                                endAdornment: <InputAdornment position="end">g</InputAdornment>,
                                // style: { width: "100px"},
                            }}
                            />
                        </Grid>
                        <Grid item>
                            <NumberInput 
                            source="nutritions.脂質"
                            label="脂質" 
                            helperText={false} 
                            InputProps={{
                                endAdornment: <InputAdornment position="end">g</InputAdornment>,
                                // style: { width: "100px"},
                            }}
                            />
                        </Grid>
                        <Grid item>
                            <NumberInput 
                            source="nutritions.ナトリウム"
                            label="ナトリウム" 
                            helperText={false} 
                            InputProps={{
                                endAdornment: <InputAdornment position="end">mg</InputAdornment>,
                                // style: { width: "100px"},
                            }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom sx={{pt: 3}}>
                献立に採用する月
            </Typography>
            <CheckboxGroupInput source="is_month_list" label="" choices={choices} optionValue="id" optionText="name" labelPlacement="bottom" 
            defaultValue={choices.map(choice => choice.id)} />
            {/* <Typography variant="h6" gutterBottom>
                <br/>
                その他
            </Typography> */}
            {/* <TextInput source="id" disabled sx={{ display: 'none' }}/>
            <TextInput source="uid" disabled sx={{ display: 'none' }}/>
            <TextInput source="date" label="過去の献立の日付" disabled sx={{ display: 'none' }}/> */}
            
        </SimpleForm>
    </Create>
)};

export const AllShow = () => {
    const month_names = ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"];
    // const activeMonths = months.filter((month, index) => record.array[index] === 1).join(', ');
    return (
    <Show actions={<PostShowActions/>}>
        <SimpleShowLayout>
            <Grid item xs={12}>
                <Grid container sx={{ p: 3 }} justifyContent="center" alignItems="center" spacing={3} rowSpacing={10}>
                <Grid item>
                <TextField source="title" />
                </Grid>
                    <Grid item>
                        <FunctionField 
                        label="Category" 
                        render={record => 
                            <Chip 
                            label={category_name[record.category]} 
                            sx={{
                                backgroundColor: category_color[record.category],
                                edgecolor: "#000000"
                            }} 
                            />} 
                        />
                    </Grid>
                    <Grid item>
                        <FunctionField 
                        label="Genre" 
                        render={record => 
                            <Chip 
                            label={genre_name[record.genre]} 
                            color="primary"
                            sx={{
                                backgroundColor: genre_color[record.genre],
                                fontColor: "#ffffff"
                            }} 
                        />} 
                        />
                    </Grid>
                </Grid>
            </Grid>

            <ArrayField source="ingredients" label="食材情報">
            {/* <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                    <TextField source="name" label="食材名"/>
                    <NumberField source="amount" label="分量"/>
                <Typography component="span" variant="body2">
                    kcal<br/>
                </Typography> */}
                <Datagrid bulkActionButtons={false}>
                    <TextField source="name" label="食材名"/>
                    <NumberField source="amount" label="分量"/>
                    <NumberField source="energy" label="エネルギー"/>
                    <NumberField source="protein" label="たんぱく質"/>
                    <NumberField source="lipid" label="脂質"/>
                    <NumberField source="sodium" label="ナトリウム"/> 
                </Datagrid>
                {/* </CardContent>
                </Card> */}
            </ArrayField>

            <FunctionField 
            label="栄養情報" 
            render={record => (
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                <Typography component="span" variant="body2">
                    エネルギー　
                </Typography>
                <NumberField source="nutritions.エネルギー" />
                <Typography component="span" variant="body2">
                    kcal<br/>
                </Typography>
                <Typography component="span" variant="body2">
                    たんぱく質　
                </Typography>
                <NumberField source="nutritions.たんぱく質" />
                <Typography component="span" variant="body2">
                    g<br/>
                </Typography>
                <Typography component="span" variant="body2">
                    脂質　
                </Typography>
                <NumberField source="nutritions.脂質" />
                <Typography component="span" variant="body2">
                    g<br/>
                </Typography>
                <Typography component="span" variant="body2">
                    ナトリウム　
                </Typography>
                <NumberField source="nutritions.ナトリウム" />
                <Typography component="span" variant="body2">
                    mg<br/>
                </Typography>
                </CardContent>
                </Card>)}
            />

            <FunctionField 
                label="献立に採用する月" 
                render={(record) => (
                    <>
                        {record.is_month_list.map((month, i) => (
                            <Chip 
                                key={i}
                                label={month_names[month-1]}
                                sx={{
                                    backgroundColor: "#ffffff",
                                    edgecolor: "#000000",
                                    // color: "#000000"
                                }} 
                            />
                        ))}
                    </>
                )}
            />

            {/* <TextField source="id" /> */}
            {/* TO DO */}
            {/* <TextField source="date" label="過去の献立の日付"/> */}
        </SimpleShowLayout>
    </Show>
)};


export const AllYokosukaShow = () => {
    const month_names = ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"];
    // const activeMonths = months.filter((month, index) => record.array[index] === 1).join(', ');
    return (
    <Show actions={<PostYokosukaShowActions/>}>
        <SimpleShowLayout>
            <Grid item xs={12}>
                <Grid container sx={{ p: 3 }} justifyContent="center" alignItems="center" spacing={3} rowSpacing={10}>
                <Grid item>
                <TextField source="title" />
                </Grid>
                    <Grid item>
                        <FunctionField 
                        label="Category" 
                        render={record => 
                            <Chip 
                            label={category_name[record.category]} 
                            sx={{
                                backgroundColor: category_color[record.category],
                                edgecolor: "#000000"
                            }} 
                            />} 
                        />
                    </Grid>
                    <Grid item>
                        <FunctionField 
                        label="Genre" 
                        render={record => 
                            <Chip 
                            label={genre_name[record.genre]} 
                            color="primary"
                            sx={{
                                backgroundColor: genre_color[record.genre],
                                fontColor: "#ffffff"
                            }} 
                        />} 
                        />
                    </Grid>
                </Grid>
            </Grid>

            <ArrayField source="ingredients" label="食材情報">
            {/* <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                    <TextField source="name" label="食材名"/>
                    <NumberField source="amount" label="分量"/>
                <Typography component="span" variant="body2">
                    kcal<br/>
                </Typography> */}
                <Datagrid bulkActionButtons={false}>
                    <TextField source="name" label="食材名"/>
                    <NumberField source="amount" label="分量"/>
                    <NumberField source="energy" label="エネルギー"/>
                    <NumberField source="protein" label="たんぱく質"/>
                    <NumberField source="lipid" label="脂質"/>
                    <NumberField source="sodium" label="ナトリウム"/> 
                </Datagrid>
                {/* </CardContent>
                </Card> */}
            </ArrayField>

            <FunctionField 
            label="栄養情報" 
            render={record => (
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                <Typography component="span" variant="body2">
                    エネルギー　
                </Typography>
                <NumberField source="nutritions.エネルギー" />
                <Typography component="span" variant="body2">
                    kcal<br/>
                </Typography>
                <Typography component="span" variant="body2">
                    たんぱく質　
                </Typography>
                <NumberField source="nutritions.たんぱく質" />
                <Typography component="span" variant="body2">
                    g<br/>
                </Typography>
                <Typography component="span" variant="body2">
                    脂質　
                </Typography>
                <NumberField source="nutritions.脂質" />
                <Typography component="span" variant="body2">
                    g<br/>
                </Typography>
                <Typography component="span" variant="body2">
                    ナトリウム　
                </Typography>
                <NumberField source="nutritions.ナトリウム" />
                <Typography component="span" variant="body2">
                    mg<br/>
                </Typography>
                </CardContent>
                </Card>)}
            />

            <FunctionField 
                label="献立に採用する月" 
                render={(record) => (
                    <>
                        {record.is_month_list.map((month, i) => (
                            <Chip 
                                key={i}
                                label={month_names[month-1]}
                                sx={{
                                    backgroundColor: "#ffffff",
                                    edgecolor: "#000000",
                                    // color: "#000000"
                                }} 
                            />
                        ))}
                    </>
                )}
            />

            {/* <TextField source="id" /> */}
            {/* TO DO */}
            {/* <TextField source="date" label="過去の献立の日付"/> */}
        </SimpleShowLayout>
    </Show>
)};