/*
ユーザ登録 -> メールアドレス認証
*/
// import * as React from 'react';
import { useState, useEffect, forwardRef } from "react";
import {
    getAuth,
    createUserWithEmailAndPassword,
    applyActionCode,
    onAuthStateChanged,
    sendEmailVerification,
    signOut,
    updateProfile
  } from "firebase/auth";
import { auth } from "../../firebase.js";
import { Navigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { spacing } from '@mui/system';
import SendIcon from '@mui/icons-material/Send';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import { Checkbox, FormControlLabel} from '@mui/material';

import Navigation from '../contents/Navigation';

const theme = createTheme();

// const api_url = "http://localhost:8000/"
const api_url = "https://api.menu-recommend.com/"

const Register = () => {
    const [registerEmail, setRegisterEmail] = useState("");
    const [registerPassword, setRegisterPassword] = useState("");
    const [registerDisplayName, setRegisterDisplayName] = useState("");
    const [user, setUser] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [snackbarState, setSnackbarState] = useState({
        open: false,
        status: 'info',
        message: '',
    });
    const [verifyMail, setVerifyMail] = useState(true);
    // const [statusSnackbar, setStatusSnackbar] = useState("");
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    // const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     event.preventDefault();
    //   };
    // console.log(registerDisplayName)
    // console.log(snackbarState)

    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });
    }, []);

    const handleClose = () => {
        setSnackbarState({
            open: false,
            status: 'info',
            message: '',
        });
    };

    /**
     * 指定されたメールアドレスとパスワードでユーザ認証する.
     * メールアドレス未認証の場合は認証メールを再度送信する
     * @param {string} email メールアドレス
     * @param {string} password パスワード
     */
    const signup = async (e) => {
        const actionCodeSettings = {
            // パスワード再設定後のリダイレクト URL
            url: 'https://menu-recommend.com/school/login?email=' + registerEmail
        }
        e.preventDefault();
        setVerifyMail(false);
        if(registerDisplayName === ''){
            // alert("ユーザ名は必須です");
            setSnackbarState({
                open: true,
                status: 'error',
                message: 'ユーザ名は必須です',
            });
        }
        else{
            try {
                const userCredencial = await createUserWithEmailAndPassword(
                    auth,
                    registerEmail,
                    registerPassword
                );
                await updateProfile(auth.currentUser, {
                    displayName: registerDisplayName//, photoURL: "https://example.com/jane-q-user/profile.jpg"
                })
                const response = await fetch(`${api_url}school/user`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Accept': 'application/json',
                      'Authorization': `Bearer ${auth.currentUser.accessToken}`
                    },
                    body: JSON.stringify({
                      "uid": `${auth.currentUser.uid}`,
                      "yokosuka": true,
                      "evals": {},
                      "details": {}
                    }),
                })
                await signOut(auth);
                
                await sendEmailVerification(userCredencial.user, actionCodeSettings)
                .then(() => {
                    setSnackbarState({
                        open: true,
                        status: 'success',
                        message: '認証メールを送信しました',
                    });
                    setTimeout(() => {
                        window.location.href = "/school/login?email="+registerEmail;
                      }, 5 * 1000);
                });
                
                // return
            } catch (error) {
                console.log(error.code)
                switch (error.code) {
                    case "auth/email-already-in-use":
                        setSnackbarState({
                            open: true,
                            status: 'error',
                            message: 'そのメールアドレスは既に使用されています',
                        });
                        break
                    case "auth/invalid-email":
                        setSnackbarState({
                            open: true,
                            status: 'error',
                            message: '無効なメールアドレスです',
                        });
                        break
                    case "auth/operation-not-allowed":
                        setSnackbarState({
                            open: true,
                            status: 'error',
                            message: 'エラーが起きました',
                        });
                        break
                    case "auth/weak-password":
                        setSnackbarState({
                            open: true,
                            status: 'error',
                            message: 'パスワードは6文字以上で設定してください',
                        });
                        break
                    case "auth/missing-password":
                        setSnackbarState({
                            open: true,
                            status: 'error',
                            message: 'パスワードを入力してください',
                        });
                        break
                    default:
                        setSnackbarState({
                            open: true,
                            status: 'error',
                            message: 'エラーが起きました' + error.code,
                        });
                        break
    
                      // その他のエラー処理
                // switch (error.code) {
                // case "auth/user-not-found":
                // case "auth/invalid-email":
                // case "auth/wrong-password":
                //     // パスワードが合致しない、ユーザが存在しなかったときの処理
                // break
                // default:
                //     // その他のエラー時の処理
                // }
                // return
            }
            }
        }

        };
    

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: 30,
        width: 130,
        lineHeight: '30px',
        backgroundColor: "#F5DCDE",
    }));

    const [isAgreed, setIsAgreed] = useState(false);


    return (
        <>

        {/* ↓ログインしていればマイページを表示 */}
        {user && verifyMail? (
            <Navigate to={`/school`} />
        ) : (
        <>
        </>)}
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarState['open']}
            autoHideDuration={6000}
            onClose={handleClose}
        >
        <Alert severity={snackbarState['status']}>{snackbarState['message']}</Alert>
        </Snackbar>

        <ThemeProvider theme={theme}>
        <Navigation/>
        <Grid sx={{ flexGrow: 1, padding: 5 }} container justifyContent="center" alignItems="center" spacing={2} rowSpacing={2}> 
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{p: 5}}>
                {/* <Grid item> */}
                        <Typography variant="h4">
                            新規登録
                        </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" spacing={3} >
                    <Grid item>
                        <Item>ユーザ名</Item>
                    </Grid>
                    <Grid item> 
                        <OutlinedInput
                            name="displayName"
                            type="displayName"
                            value={registerDisplayName}
                            onChange={(e) => setRegisterDisplayName(e.target.value)}
                            // label="Select"
                            // defaultValue={nutritions[2]['value']}
                            size="small"
                            // helperText="Please select your currency"
                            sx={{ width: '30ch' }}
                            required
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                    <Grid item>
                        <Item>メールアドレス</Item>
                    </Grid>
                    <Grid item> 
                        <OutlinedInput
                            name="email"
                            type="email"
                            value={registerEmail}
                            onChange={(e) => setRegisterEmail(e.target.value)}
                            // label="Select"
                            // defaultValue={nutritions[2]['value']}
                            size="small"
                            // helperText="Please select your currency"
                            sx={{ width: '30ch' }}
                            required
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" spacing={3} >
                    <Grid item>
                        <Item>パスワード</Item>
                    </Grid>
                    <Grid item>
                    <FormControl variant="outlined">
                        {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            value={registerPassword}
                            onChange={(e) => setRegisterPassword(e.target.value)}
                            size="small"
                            sx={{ width: '30ch' }}
                            required
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                //   onMouseDown={handleMouseDownPassword}
                                edge="end"
                                >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            }
                            // label="Password"
                        />
                        </FormControl>
                        {/* <TextField 
                        name="password"
                        type="password"
                        value={registerPassword}
                        onChange={(e) => setRegisterPassword(e.target.value)}
                        size="small"
                        sx={{ width: '20ch' }}
                        /> */}
                    </Grid>
                </Grid>
            </Grid>    
            <Grid item xs={12}>   
                <Grid container sx={{pt:2}} justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item>
                        <FormControlLabel
                            control={<Checkbox checked={isAgreed} onChange={() => setIsAgreed(!isAgreed)} />}
                            label={
                                <Typography variant="body2">
                                <Link href="/school/terms">利用規約</Link>と 
                                <Link href="/school/privacy">プライバシーポリシー</Link> に同意する
                                </Typography>
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>   
                <Grid container sx={{padding: 3}} justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item>
                        {/* <Button href="/school/result-calendar" variant="outlined" endIcon={<SendIcon />}>登録する</Button> */}
                        <Button variant="outlined" disabled={!isAgreed} endIcon={<SendIcon />}  onClick={signup}>登録する</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item >
                            <Link href="/school/login" variant="body2">ログインはこちら</Link>
                        </Grid>
                </Grid>
            </Grid>
        </Grid>

        </ThemeProvider>
        </>
    );
};
export default Register;